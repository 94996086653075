<template>
<div>
  <div
    style="display: flex; justify-content: center"
    class="mt-4 paginator-custom"
  >
    {{ logic }}
    <div class="box-white">
      <div class="leftArrow">
        <div class="circle mr-2">
          <span
            :class="'pi pi-angle-double-left ' + firstPage.options"
            @click="firstPageFunction"
          ></span>
        </div>

        <div class="circle">
          <span
            :class="'pi pi-angle-left ' + prevPage.options"
            @click="previusPageFunction"
          ></span>
        </div>
      </div>
      <div
        :v-if="totalPages"
        v-for="item in pages"
        :key="item.label"
      >
        <div v-if="item.active == true">
          <span class="link active" @click="number(item)">{{
            item.label
          }}</span>
        </div>
        
        <div v-else-if="item.active == false">
          <span class="link inactive" @click="number(item)">{{
            item.label
          }}</span>
        </div>
      </div>
      <div class="rightArrow">
        <div class="circle">
          <span
            :class="'pi pi-angle-right ' + nextPage.options"
            @click="nextPageFunction"
          ></span>
        </div>
        <div class="circle ml-2">
          <span
            :class="'pi pi-angle-double-right ' + lastPage.options"
            @click="lastPageFunction"
          ></span>
        </div>
      </div>
    </div>
  </div>
  <div class="paginator_total_items">
      Total: {{total}} 
  </div>
</div>
</template>
<script>
import axios from "axios";
import { ProductsService } from "./../services/Taxonomia/ProductsService";
import { AreaService } from "./../services/AreaService";

export default {
  props: [
    "options",
    "links",
    "props",
    "oldProducts",
    "link",
    "params",
    "paramsAbm",
    "total",
  ],

  data() {
    return {
      nextPage: [], //1 FLECHA DIREITA
      prevPage: [], //1 FLECHA ESQUERDA
      totalPages: null,
      firstPage: [], //2 FLECHAS ESQUERDA
      lastPage: [], //2 FLECHAS DIREITA
      lastPageNum: [],

      path: null,

      pages: [], //PAGES COM PROPRIEDADES
      pagesGuard: [],

      paginator: null,
    };
  },

  computed: {
    logic() {
      this.paginator = this.options;
    },
  },

  watch: {
    options(newVal, oldVal) {
      console.log(this.options)
      this.paginator = newVal;
    },

    paginator(newVal, oldVal) {
      //TODA LOGICA DO PAGINATOR
      console.log(newVal);
      this.totalPages = newVal.total_pages;
      this.path = newVal.path;
      
      console.log(this.totalPages)
      if (this.totalPages == 1 || this.totalPages == 0) {
        console.log(this.pages)
        this.prevPage.link = null;
        this.prevPage.options = "disabled";
        this.nextPage.options = "disabled";

        this.nextPage.link = null;

        this.firstPage.link = null;
        this.lastPage.link = null;
        this.firstPage.options = "disabled";
        this.lastPage.options = "disabled";

        this.totalPages = 1;

        this.lastPageNum = null;

        this.pages = newVal.links.filter(
          (item) => item.label != "pagination.previous"
        );

        this.pages = this.pages.filter(
          (item) => item.label != "pagination.next"
        );

        
      } else {
        this.pages = newVal.links.filter(
          (item) => item.label != "pagination.previous"
        );
        this.pages = this.pages.filter(
          (item) => item.label != "pagination.next"
        );

        var activeNumber;
        var id = 0;

        this.lastPageNum = newVal.last_page;
        this.pages.map((item) => {
          item.id = id++;
          if (item.active == true) {
            activeNumber = item.label;

            if (item.label == newVal.last_page) {
              this.firstPage.link = newVal.first_page_url;
              this.firstPage.options = "";

              this.prevPage.link = newVal.prev_page_url;
              this.prevPage.options = "";

              this.lastPage.link = null;
              this.lastPage.options = "disabled";

              this.nextPage.link = null;
              this.nextPage.options = "disabled";
            } else if (item.label == 1) {
              this.firstPage.link = null;
              this.firstPage.options = "disabled";

              this.prevPage.link = null;
              this.prevPage.options = "disabled";

              this.lastPage.link = newVal.last_page_url;
              this.lastPage.options = "";

              this.nextPage.link = newVal.next_page_url;
              this.nextPage.options = "";
            } else {
              this.firstPage.link = newVal.first_page_url;
              this.prevPage.link = newVal.prev_page_url;
              this.firstPage.options = "";
              this.prevPage.options = "";

              this.lastPage.link = newVal.last_page_url;
              this.nextPage.link = newVal.next_page_url;
              this.nextPage.options = "";
              this.lastPage.options = "";
            }
          }
        });

        this.pagesGuard = this.pages;

        if (
          activeNumber >= 2 &&
          activeNumber <= this.totalPages - 1 &&
          this.totalPages > 5
        ) {
          this.pagesGuard.map((item) => {
            if (item.label == activeNumber) {
              activeNumber -= 1;
              var selectArray = [];

              var somenFirst = false;
              var somenLast = false;

              if (this.pagesGuard[activeNumber - 2] != null) {
                selectArray.push(this.pagesGuard[activeNumber - 2]);
                selectArray.push(this.pagesGuard[activeNumber - 1]);
              } else if (this.pagesGuard[activeNumber - 1] != null) {
                selectArray.push(this.pagesGuard[activeNumber - 1]);
                somenFirst = true;
              }

              selectArray.push(this.pagesGuard[activeNumber]);

              if (this.pagesGuard[activeNumber + 2] != null) {
                selectArray.push(this.pagesGuard[activeNumber + 1]);
                selectArray.push(this.pagesGuard[activeNumber + 2]);
              } else if (this.pagesGuard[activeNumber + 1] != null) {
                selectArray.push(this.pagesGuard[activeNumber + 1]);
                somenLast = true;
              }

              if (somenFirst == true && somenLast == false) {
                if (this.pagesGuard[activeNumber + 3] != null) {
                  selectArray.push(this.pagesGuard[activeNumber + 3]);
                  this.pages = selectArray;
                }
              } else if (somenLast == true && somenFirst == false) {
                if (this.pagesGuard[activeNumber - 3] != null) {
                  var ordenado = [];
                  var numberOrdenado = [];

                  selectArray.push(this.pagesGuard[activeNumber - 3]);

                  selectArray.map((item) => {
                    numberOrdenado.push(item.label);
                  });

                  numberOrdenado.sort((a, b) => a - b);

                  for (var i = 0; i <= selectArray.length; i++) {
                    selectArray.map((item) => {
                      if (item.label == numberOrdenado[i]) {
                        ordenado.push(item);
                      }
                    });
                  }

                  this.pages = ordenado;
                }
              } else {
                this.pages = selectArray;
              }
            }
          });
        } else if (activeNumber < 2 && this.totalPages > 5) {
          var max = 5;

          this.pages = [];

          this.pagesGuard.map((item) => {
            if (this.pages.length < max) {
              this.pages.push(item);
            } else {
            }
          });
        } else if (activeNumber <= this.totalPages && this.totalPages > 5) {
          activeNumber -= 1;
          var max = 5;

          this.pages = [];
          this.pages.push(this.pagesGuard[activeNumber]);

          for (var i = 1; i <= 4; i++) {
            this.pages.push(this.pagesGuard[activeNumber - i]);
          }

          this.pages = this.pages.reverse();
        } else {
          this.pages = this.pagesGuard;
        }
      }
    },
  },

  methods: {
    incrementTotal(val){
      this.total += val; 
    },
    nextPageFunction() {
      if (this.nextPage.options == "") {
        if (this.nextPage.link != null) {
          this.$emit("loading", true);
          if (this.oldProducts == true) {
            //DESCARTADOS && PENDIENTES
            this.handlerSetas(this.nextPage);
          } else if (this.params == true) {
            //TAXONOMIZAODS
            this.handlerNumber(this.nextPage);
          } else if (this.paramsAbm == true) {
            //ABM
            this.handlerSetasABM(this.nextPage);
          } else {
            axios
              .get(this.nextPage.link)
              .then((result) => {
                this.paginator = result.data;
                this.$emit("nextPage", result.data);
              })
              .catch((err) => {});
          }
        }
      }
    },
    previusPageFunction() {
      if (this.prevPage.options == "") {
        if (this.prevPage.link != null) {
          this.$emit("loading", true);

          if (this.oldProducts == true) {
            //DESCARTADOS && PENDIENTES
            this.handlerSetas(this.prevPage);
          } else if (this.params == true) {
            //TAXONOMIZADOS
            this.handlerNumber(this.prevPage);
          } else if (this.paramsAbm == true) {
            //ABM
            this.handlerSetasABM(this.prevPage);
          } else {
            axios
              .get(this.prevPage.link)
              .then((result) => {
                this.paginator = result.data;
                this.$emit("prevPage", result.data);
              })
              .catch((err) => {});
          }
        }
      }
    },
    firstPageFunction() {
      console.log(this.firstPage);
      if (this.firstPage.options == "") {
        if (this.firstPage.link != null) {
          console.log("click");
          this.$emit("loading", true);
          if (this.oldProducts == true) {
            //DESCARTADOS && PENDIENTES
            this.handlerSetas(this.firstPage);
          } else if (this.params == true) {
            //TAXONOMIZADOS
            this.handlerNumber(this.firstPage);
          } else if (this.paramsAbm == true) {
            //ABM
            this.handlerSetasABM(this.firstPage);
          } else {
            axios
              .get(this.firstPage.link)
              .then((result) => {
                this.paginator = result.data;
                this.$emit("firstPage", result.data);
              })
              .catch((err) => {});
          }
        }
      }
    },
    lastPageFunction() {
      if (this.lastPage.options == "") {
        if (this.lastPage.link != null) {
          this.$emit("loading", true);

          if (this.oldProducts == true) {
            //DESCARTADOS && PENDIENTES
            this.handlerSetas(this.lastPage);
          } else if (this.params == true) {
            //TAXONOMIZADOS
            this.handlerNumber(this.lastPage);
          } else if (this.paramsAbm == true) {
            //ABM
            this.handlerSetasABM(this.lastPage);
          } else {
            axios
              .get(this.lastPage.link)
              .then((result) => {
                this.paginator = result.data;
                this.$emit("lastPage", result.data);
              })
              .catch((err) => {});
          }
        }
      }
    },

    number(link) {
      if (link.active != true) {
        this.$emit("loading", true);
        if (this.oldProducts == true) {
          //DESCARTADOS && PENDIENTES
          var currentPage;
          var pages = [];
          var data = [];

          this.pagesGuard.map((item) => {
            if (item.label == link.label) {
              item.active = true;
              currentPage = item.label;
              pages.push(item);
            } else {
              item.active = false;
              pages.push(item);
            }
          });

          console.log();

          data.links = pages;
          data.prev_page_url = this.path + (parseInt(currentPage) - 1);
          data.first_page_url = this.path + 1;
          data.next_page_url = this.path + (parseInt(currentPage) + 1);
          data.last_page_url = this.path + this.totalPages;
          data.total_pages = this.totalPages;
          data.current_page = currentPage;
          data.first_page = 1;
          data.last_page = this.lastPageNum;
          data.path = this.path;

          this.paginator = data;

          this.$emit("numberPage", link.label, this.props);
        } else if (this.params == true) {
          //TAXONOMIZADOS
          var currentPage;
          var pages = [];
          var data = [];

          this.pagesGuard.map((item) => {
            if (item.label == link.label) {
              item.active = true;
              currentPage = item.label;
              pages.push(item);
            } else {
              item.active = false;
              pages.push(item);
            }
          });

          console.log();

          data.links = pages;
          data.prev_page_url = this.path + (parseInt(currentPage) - 1);
          data.next_page_url = this.path + (parseInt(currentPage) + 1);
          data.last_page_url = this.path + this.totalPages;
          data.total_pages = this.totalPages;
          data.current_page = currentPage;
          data.first_page = 1;
          data.last_page = this.lastPageNum;
          data.path = this.path;

          this.paginator = data;

          this.$emit("numberPage", link.label, this.props);
        } else if (this.paramsAbm == true) {
          //ABM
          this.handlerNumberABM(link);
        } else {
          axios
            .get(link.url)
            .then((result) => {
              this.paginator = result.data;
              this.$emit("numberPage", result.data);
            })
            .catch((err) => {});
        }
      }
    },

    handlerSetas(linkObj) {
      var link = linkObj.link.split("=");
      link.label = link[1];

      var currentPage;
      var pages = [];

      this.pagesGuard.map((item) => {
        if (item.label == link.label) {
          item.active = true;
          currentPage = item.label;
          pages.push(item);
        } else {
          item.active = false;
          pages.push(item);
        }
      });

      var data = [];

      data.links = pages;
      data.first_page_url = this.path + 1;
      data.prev_page_url = this.path + (parseInt(currentPage) - 1);
      data.first_page_url = this.path + 1;
      data.next_page_url = this.path + (parseInt(currentPage) + 1);
      data.last_page_url = this.path + this.totalPages;
      data.total_pages = this.totalPages;
      data.current_page = currentPage;
      data.first_page = 1;
      data.last_page = this.lastPageNum;
      data.path = this.path;

      this.paginator = data;

      this.$emit("numberPage", currentPage, this.props);
    },

    handlerNumber(linkObj) {
      var link = linkObj.link.split("=");
      link.label = link[1];

      var currentPage;
      var pages = [];
      var data = [];

      this.pagesGuard.map((item) => {
        if (item.label == link.label) {
          item.active = true;
          currentPage = item.label;
          pages.push(item);
        } else {
          item.active = false;
          pages.push(item);
        }
      });

      console.log(this.path);

      data.links = pages;
      data.prev_page_url = this.path + (parseInt(currentPage) - 1);
      data.next_page_url = this.path + (parseInt(currentPage) + 1);
      data.first_page_url = this.path + 1;
      data.last_page_url = this.path + this.totalPages;
      data.total_pages = this.totalPages;
      data.current_page = currentPage;
      data.first_page = 1;
      data.last_page = this.lastPageNum;
      data.path = this.path;

      this.paginator = data;

      this.$emit("numberPage", link.label, this.props);
    },

    handlerSetasABM(linkObj) {
      console.log(linkObj);
      var link = linkObj.link.split("=");
      link.label = link[1];
      console.log(link.label);

      var pathG = this.path;
      var pathURL = pathG.split("?");
      pathURL = pathURL[0];

      console.log(pathURL);

      axios
        .post(
          pathURL,
          {
            name: this.props.name,
            oder_by: this.props.order_by,
            asc: this.props.asc,
            page: link.label,
          },
          { headers: { "page-size": 20 } }
        )

        .then((result) => {
          var currentPage;
          var pages = [];

          this.pagesGuard.map((item) => {
            if (item.label == link.label) {
              item.active = true;
              currentPage = link.label;
              pages.push(item);
            } else {
              item.active = false;
              pages.push(item);
            }
          });

          result.data.links = pages;

          result.data.first_page_url = this.path + "?page=" + 1;
          result.data.prev_page_url =
            this.path + "?page=" + (parseInt(currentPage) - 1);
          result.data.next_page_url =
            this.path + "?page=" + (parseInt(currentPage) + 1);

          result.data.last_page_url = this.path + "?page=" + this.totalPages;
          result.data.total_pages = this.totalPages;
          result.data.total = this.total;
          result.data.current_page = currentPage;
          result.data.first_page = 1;
          result.data.last_page = this.lastPageNum;
          result.data.path = this.path;

          this.paginator = result.data;
          console.log(result.data);
          this.$emit("lastPage", result.data, result.data.current_page);
        })
        .catch((err) => {});
    },

    handlerNumberABM(linkObj) {
      var pathG = this.path;
      var pathURL = pathG.split("?");
      pathURL = pathURL[0];

      axios
        .post(
          pathURL,
          {
            name: this.props.name,
            oder_by: this.props.order_by,
            asc: this.props.asc,
            page: linkObj.label,
          },
          { headers: { "page-size": 20 } }
        )

        .then((result) => {
          var currentPage;
          var pages = [];

          this.pagesGuard.map((item) => {
            if (item.label == linkObj.label) {
              item.active = true;
              currentPage = linkObj.label;
              pages.push(item);
            } else {
              item.active = false;
              pages.push(item);
            }
          });

          result.data.links = pages;

          result.data.first_page_url = this.path + "?page=" + 1;
          result.data.prev_page_url =
            this.path + "?page=" + (parseInt(currentPage) - 1);
          result.data.next_page_url =
            this.path + "?page=" + (parseInt(currentPage) + 1);

          result.data.last_page_url = this.path + "?page=" + this.totalPages;
          result.data.total_pages = this.totalPages;
          result.data.total = this.total;
          result.data.current_page = currentPage;
          result.data.first_page = 1;
          result.data.last_page = this.lastPageNum;
          result.data.path = this.path;

          this.paginator = result.data;

          this.$emit("numberPage", result.data, result.data.current_page);
        })
        .catch((err) => {});
    },
  },
};
</script>
