<template>
  <div>
    <div>
      <!-- @_____ == function callback -->
      <Query
        :paisList="paisList"
        :chipsCategoria="ChipsCategoria"
        :chipsRetail="ChipsRetail"
        @ShowPais="showPais"
        @ShowRetail="showRetail"
        @HideRetail="hideRetail"
        @ShowCategoria="showCategoria"
        @HideCategoria="hideCategoria"
        @ClickClear="clearList"
        @getObtener="getObtener"
        :btnLeftLabel="'Obtener'"
        :width="'255px'"
      ></Query>
    </div>

    <!-- @_____ == function callback -->
    <div :class="collapse">
      <Table
        :values="productService"
        :collapse="collapse"
        :descartar="true"
        @retroceder="retrocederFunc"
      ></Table>
    </div>

    <div :class="'container-custom ' + spinner">
      <ProgressSpinner />
    </div>

    <div :class="collapse" v-if="productService.length >= 1">
      <Paginator
        :options="options"
        :links="links"
        :oldProducts="true"
        :props="props"
        :link="link"
        @nextPage="handlerPaginator"
        @lastPage="handlerPaginator"
        @prevPage="handlerPaginator"
        @firstPage="handlerPaginator"
        @numberPage="handlerPaginator"
        @loading="loadings"
        :total="total"
      ></Paginator>
    </div>
  </div>
</template>
<script>
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Button from "primevue/button";
import Query from "../../components/Query/Query.vue";
import Table from "../../components/Tables/Table.vue";
import ProgressSpinner from "primevue/progressspinner";

//SERVICES
import { CountryService } from "../../services/Taxonomia/CountryService";
import { RetailsService } from "../../services/Taxonomia/RetailsService";
import { CategoriaService } from "../../services/Taxonomia/CategoriaService";

import { OldBrandService } from "../../services/Taxonomia/OldBrandService";

import { ProductsService } from "../../services/Taxonomia/ProductsService";

import Paginator from "../../components/Paginator.vue";
import env from "../../env.js";

export default {
  components: {
    Dropdown,
    MultiSelect,
    Button,
    Table,
    Query,
    ProgressSpinner,
    Paginator,
  },
  data() {
    return {
      // DATA - API
      productService: [],
      loading: false,

      //CHIPS
      //Pais
      paisList: [],
      paisSelect: null,
      paisSelectColumn: null,

      paisQuery: null,

      //Retail
      ChipsRetail: [],

      selectedRetail: null,

      //Categoria
      ChipsCategoria: [],
      List: [],

      selectedCategoria: null,

      options: null,
      links: null,

      props: null,
      link: null,

      //Css propriedades
      widthTable: "auto",
      collapse: "table-collapsed",
      spinner: "spinner-not",

      request: false,
      
      total: 0,
    };
  },

  created() {
    this.loadCountry(); //Carrega todos os paises para os inputs
  },

  methods: {
    //TABLE
    loadCountry() {
      CountryService.getAll() //Carrega todos os paises para os inputs
        .then((result) => {
          result.data = result.data.map((item) => {
            item.id = item.name;
            item.name_backup = item.name;

            item.name = item.name + " | " + item.country;
            this.paisList.push(item);
          });
        })
        .catch((err) => {});
    },

    handlerPaginator(page, props) {
      var id = 0;
      console.log(page, props);

      ProductsService.getDescartadosParams(
        [props.country],
        props.retail,
        props.category,
        page
      )
        .then((result) => {
          this.productService = result.data.data;
          this.loadings(false);
        })
        .catch((err) => {});
    },

    //QUERY
    showPais(value) {
      if (value != null) {
        this.selectedBrand = null; //Quando seleciona um pais todos os outros campos selecionados devem ser apagados
        this.selectedRetail = null;
        this.selectedCategoria = null;
        this.ChipsBrand = [];
        this.ChipsRetail = [];
        this.ChipsCategoria = [];

        this.paisSelect = value;
        RetailsService.getPerCountry(this.paisSelect.country) //Request retails
          .then((result) => {
            var names = [];
            var retailABC = [];

            this.ChipsRetail = result.data.map((item) => {
              let retailUC = item.retail.toUpperCase();
              item.name = retailUC + " | " + this.paisSelect.country;
              names.push(item.name);
              item.id = this.paisSelect.country;
              return item;
            });

            names.sort();

            names.map((name) => {
              this.ChipsRetail.map((retail) => {
                if (name == retail.name) {
                  retailABC.push(retail);
                }
              });
            });

            this.ChipsRetail = retailABC;
          })
          .catch((err) => {});
      }
    },

    showRetail(val) {
      if (this.paisSelect == null) {
        this.$store.dispatch("alert_error", {
          //Alert
          message: "Seleccione el país primero",
          time: 2000,
        });
      } else if (this.paisSelect != null) {
        if (this.paisSelect.length == 0) {
          this.$store.dispatch("alert_error", {
            //Alert
            message: "Seleccione el país primero",
            time: 2000,
          });
        }
      }

      this.request = true;
    },

    hideRetail(val) {
      if (val != null) {
        if (val == this.selectedRetail) {
          //caso os valor novo seja igual ao antigo somente nao faz a request
          this.selectedRetail = val;
        } else {
          this.selectedRetail = val;

          var retail = [];

          this.selectedRetail.map((item) => {
            //Trata os valores de selectedRetail para o back-end aceitar
            retail.push(item.retail);
          });
          this.ChipsCategoria = []; //Clear chips categoria

          CategoriaService.getPerCountry(this.paisSelect.country, retail) //Request categorias
            .then((result) => {
              result.data.map((item) => {
                item.name = item.category; //item.name recebe categoria name para os chips aceitarem
                item.id = item.category; //item.id recebe categoria name para os chips aceitarem
                

                var names = [];
                var categoriaABC = [];

                this.ChipsCategoria.push(item);

                this.ChipsCategoria.map((categoria) =>{
                  names.push(categoria.name)
                });

                names.sort();

                names.map((name) => {
                  this.ChipsCategoria.map((categoria) => {
                    if (name == categoria.name) {
                      categoriaABC.push(categoria);
                    }
                  });
                });

                this.ChipsCategoria = categoriaABC;

                this.request = false;
              });
            })
            .catch((err) => {});

          if (this.ChipsCategoria.length > 0) {
            //Alert
            this.$store.dispatch("alert_success_bottom", {
              message: "Cargas totales - " + this.ChipsCategoria.length,
              time: 2000,
            });
          }
        }
      }
    },

    showCategoria(val) {
      if (this.request == true) {
        //Caso nao esteja em request
      } else {
        if (this.selectedRetail == null) {
          //Alert
          this.$store.dispatch("alert_error", {
            message: "Seleccione el retail primero",
            time: 2000,
          });
        } else if (this.selectedRetail != null) {
          //Alert
          if (this.selectedRetail.length == 0) {
            this.$store.dispatch("alert_error", {
              message: "Seleccione el retail primero",
              time: 2000,
            });
          }
        }
      }
    },

    hideCategoria(val) {
      //Methodo do vueprime
      if (val == this.selectedCategoria) {
        //Verifica se o valor novo é igual ao valor selecionado
        this.selectedCategoria = val; //Passa o valor novo para selecionado
      } else {
        this.selectedCategoria = val; //Passa o valor novo para selecionado
        this.selectedBrand = null; //Apaga os brands selecionados
      }
    },

    //QUERY BTN

    clearList() {
      this.paisSelect = null; //Clear all values
      this.ChipsRetail = [];
      this.ChipsCategoria = [];
      this.ChipsBrand = [];
      this.paisSelect = null;
      this.selectedRetail = null;
      this.selectedCategoria = null;
      this.selectedBrand = null;

      this.productService = [];

      this.widthTable = "auto"; //Css propriedades
      this.collapse = "table-collapsed"; //Css propriedades
    },

    getObtener(value) {
      this.productService = []; //Clear values
      this.widthTable = "auto"; //Css propriedades
      this.collapse = "table-collapsed"; //Css propriedades

      if (!value.country) {
        this.$store.dispatch("alert_error", {
          //Alert
          message: "Seleccione el país primero",
          time: 2000,
        });
      } else if (!value.retail) {
        this.$store.dispatch("alert_error", {
          //Alert
          message: "Seleccione las retail primero",
          time: 2000,
        });
      } else if (value.category || value.category_abm) {
        this.spinner = "spinner-loading"; //Css propriedades
        this.loading = true; //Css propriedades

        value.category = value.category.map((item) => {
          return item.name;
        }); //Ajustando as variaveis
        value.retail = value.retail.map((item) => {
          return item.retail;
        }); //Ajustando as variaveis

        ProductsService.getDescartadosCount(
          value.country,
          value.retail,
          value.category
        )
          .then((result) => {
            var data = [];
            data.current_page = 1;
            data.first_page_url = env.DESCARTADOS + 1;
            data.first_page = 1;
            data.path = env.DESCARTADOS;
            data.last_page = result.data.total_pages;
            data.total_pages = result.data.total_pages;
            data.next_page_url = env.DESCARTADOS + (data.current_page + 1);
            data.total = result.data.total;

            this.total = result.data.total;

            if (data.current_page == 1) {
              data.prev_page_url = env.DESCARTADOS + data.current_page;
            } else {
              data.prev_page_url = env.DESCARTADOS + (data.current_page - 1);
            }

            data.last_page_url = env.DESCARTADOS + result.data.total_pages;

            data.links = [
              {
                active: false,
                label: "pagination.previous",
                url: "",
              },
            ];

            for (var i = 1; i <= result.data.total_pages; i++) {
              var bool;
              if (i == 1) {
                bool = true;
              } else {
                bool = false;
              }
              var obj = {
                active: bool,
                label: i,
                url: env.DESCARTADOS + i,
              };

              data.links.push(obj);
            }

            var next = {
              active: true,
              label: "pagination.next",
              url: env.DESCARTADOS + result.data.total_pages,
            };

            data.links.push(next);

            this.link = env.DESCARTADOS;

            if (data.current_page <= 0) {
              return (data.current_page = 0);
            }

            console.log(result.data.total_pages);

            if (data.current_page > result.data.total_pages) {
              return (data.current_page = result.data.total_pages);
            }

            console.log(data);

            this.props = {
              country: value.country,
              retail: value.retail,
              category: value.category,
              brand: value.brand,
            };
            this.options = data;
            this.links = data.links;

            console.log(data);
          })
          .catch((err) => {});

        ProductsService.getDescartados(
          value.country,
          value.retail,
          value.category
        ) //Request, e montando o objeto para passar para props
          .then((result) => {
            this.productService = result.data.data;

            this.loadings(false);
          })
          .catch((err) => {});
      } else {
        this.$store.dispatch("alert_error", {
          //Alert
          message: "Seleccione las categorías primero",
          time: 2000,
        });
      }
    },

    //BUTTONS

    cancelar(click) {
      this.productService.map((item) => {
        if (item.id == click) {
          //Limpa todos os campos de uma coluna que efetuou o click
          item.nombre = null;
          item.area_abm = [];
          item.division_abm = [];
          item.categoria_abm = [];
          item.feature_1_abm = [];
          item.feature_2_abm = [];
          item.feature_3_abm = [];
          item.feature_4_abm = [];
          item.feature_5_abm = [];
          item.brand_abm = [];
          item.options.division_abm.disabled = true;
          item.options.categoria_abm.disabled = true;
          item.options.features.disabled = true;
        }
      });
    },

    loadings(boolean) {
      if (boolean == true) {
        this.collapse = "table-collapsed"; //Css propriedades
        this.spinner = "spinner-loading"; //Css propriedades
      } else {
        setTimeout(() => {
          this.collapse = "not-collapsed"; //Css propriedades
          this.spinner = "spinner-not"; //Css propriedades
        }, 1500);
      }
    },

    retrocederFunc() {
      this.total -= 1;
    },
  },
};
</script>
<style lang=""></style>
