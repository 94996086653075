<template>
  <div>
    <Dialog
      :visible="this.visible"
      :style="{ width: '450px' }"
      :header="header"
      :closable="false"
      :modal="true"
      class="dialog-custom dialog-danger"
    >
      <template #header>
        <span id="pv_id_4_header" class="p-dialog-title">{{ header }}</span>
        <div class="p-dialog-header-icons visible">
          <!----><button
            aria-label="close"
            type="button"
            @click="$emit('close')"
            tabindex="-1"
            class="p-dialog-header-icon p-dialog-header-close p-link"
          >
            <span class="p-dialog-header-close-icon pi pi-times"></span>
          </button>
        </div>
      </template>
      <div class="confirmation-content">
        <div class="row">
          <div class="col p-1 mt-3">
            <div class="p-field p-col-12">
              <div>
                <h4 style="text-align: center; padding:0px">{{text}}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          :label="btnLeft"
          @click="$emit('close')"
          icon="pi pi-check"
          class="button-custom-invert"
        />
        <Button
          :label="btnRight"
          @click="$emit('del', id)"
          icon="pi pi-times"
          class="button-custom-danger"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
export default {
  components: {
    Dialog,
    Button,
    InputText,
  },
  data() {
    return {
    };
  },
  props: ["header", "visible", "inputLabel", "text", "id", 'btnLeft', 'btnRight'],
  computed: {},

  methods: {
    hideDialog() {
      this.newAreaDialog = false;
    },
  },
};
</script>
<style lang="">
</style>