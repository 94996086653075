<template>
  <div>
    <div>
      <!-- @_____ == function callback -->
      <Query
        type="pendiente"
        :paisList="paisList"
        :chipsCategoria="ChipsCategoria"
        :chipsRetail="ChipsRetail"
        :chipsBrand="ChipsBrand"
        @ShowPais="showPais"
        @ShowRetail="showRetail"
        @HideRetail="hideRetail"
        @ShowCategoria="showCategoria"
        @HideCategoria="hideCategoria"
        @ShowBrand="showBrand"
        @HideBrand="hideBrand"
        @ClickClear="clearList"
        @getObtener="getObtener"
        :btnLeftLabel="'Obtener'"
        :width="'200px'"
      ></Query>
    </div>

    <!-- @_____ == function callback -->
    <div :class="collapse" :v-if="productService.length >= 1">
      <Table
        :values="productService"
        :collapse="collapse"
        @taxonomizado="taxonomizadoFunc"
      ></Table>
    </div>

    <div :class="'container-custom ' + spinner">
      <ProgressSpinner />
    </div>
    <div :class="collapse" v-if="productService.length >= 1">
      <Paginator
        :options="options"
        :links="links"
        :oldProducts="true"
        :props="props"
        :link="link"
        @nextPage="handlerPaginator"
        @lastPage="handlerPaginator"
        @prevPage="handlerPaginator"
        @firstPage="handlerPaginator"
        @numberPage="handlerPaginator"
        @loading="loadings"
        :total="total"
      ></Paginator>
    </div>
  </div>
</template>
<script>
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Button from "primevue/button";
import Query from "../../components/Query/Query.vue";
import Table from "../../components/Tables/Table.vue";
import ProgressSpinner from "primevue/progressspinner";

//SERVICES
import { CountryService } from "../../services/Taxonomia/CountryService";
import { RetailsService } from "../../services/Taxonomia/RetailsService";
import { CategoriaService } from "../../services/Taxonomia/CategoriaService";

import { OldBrandService } from "../../services/Taxonomia/OldBrandService";

import { ProductsService } from "../../services/Taxonomia/ProductsService";

import Paginator from "./../../components/Paginator.vue";

import env from "./../../env.js";

export default {
  components: {
    Dropdown,
    MultiSelect,
    Button,
    Table,
    Query,
    ProgressSpinner,
    Paginator,
  },
  data() {
    return {
      // DATA - API
      productService: [],
      loading: true,

      //CHIPS
      //Pais
      paisList: [],
      paisSelect: null,
      paisSelectColumn: null,

      paisQuery: null,

      //Retail
      ChipsRetail: [],

      selectedRetail: null,

      //Categoria
      ChipsCategoria: [],
      List: [],

      selectedCategoria: null,

      //Brand
      ChipsBrand: [],

      selectedBrand: null,

      props: null,
      link: null,

      options: null,
      links: null,

      //Css propriedades
      widthTable: "auto",
      collapse: "table-collapsed",
      spinner: "spinner-not",

      //Alert
      time: 2000,

      total: 0,

      request: false,
    };
  },

  created() {
    
    this.loadCountry(); //Carrega todos os paises para os inputs
  },

  methods: {
    //TABLE
    loadCountry() {
      CountryService.getAll() //Carrega todos os paises para os inputs
        .then((result) => {
          result.data = result.data.map((item) => {
            item.id = item.name;
            item.name_backup = item.name;

            item.name = item.name + " | " + item.country;
            this.paisList.push(item);
          });
        })
        .catch((err) => {});
    },

    //QUERY
    showPais(value) {
      if (value != null) {
        this.selectedBrand = null; //Quando seleciona um pais todos os outros campos selecionados devem ser apagados
        this.selectedRetail = null;
        this.selectedCategoria = null;
        this.ChipsBrand = [];
        this.ChipsRetail = [];
        this.ChipsCategoria = [];

        this.paisSelect = value;
        RetailsService.getPerCountry(this.paisSelect.country) //Request retails
          .then((result) => {
            var names = [];
            var retailABC = [];

            this.ChipsRetail = result.data.map((item) => {
              let retailUC = item.retail.toUpperCase();
              item.name = retailUC + " | " + this.paisSelect.country;
              names.push(item.name);
              item.id = this.paisSelect.country;
              return item;
            });

            names.sort();

            names.map((name) => {
              this.ChipsRetail.map((retail) => {
                if (name == retail.name) {
                  retailABC.push(retail);
                }
              });
            });

            this.ChipsRetail = retailABC;
          })
          .catch((err) => {});
      }
    },

    showRetail(val) {
      if (this.paisSelect == null) {
        this.$store.dispatch("alert_error", {
          //Alert
          message: "Seleccione el país primero",
          time: this.time, //Padrao 2000,
        });
      } else if (this.paisSelect != null) {
        if (this.paisSelect.length == 0) {
          this.$store.dispatch("alert_error", {
            //Alert
            message: "Seleccione el país primero",
            time: this.time, //Padrao 2000,
          });
        }
      }

      this.request = true;
    },

    hideRetail(val) {
      if (val != null) {
        if (val == this.selectedRetail) {
          //caso os valor novo seja igual ao antigo somente nao faz a request
          this.selectedRetail = val;
        } else {
          this.selectedRetail = val;

          var retail = [];

          this.selectedRetail.map((item) => {
            //Trata os valores de selectedRetail para o back-end aceitar
            retail.push(item.retail);
          });
          this.ChipsCategoria = []; //Clear chips categoria

          CategoriaService.getPerCountry(this.paisSelect.country, retail) //Request categorias
            .then((result) => {
              result.data.map((item) => {
                item.name = item.category; //item.name recebe categoria name para os chips aceitarem
                item.id = item.category; //item.id recebe categoria name para os chips aceitarem

                var names = [];
                var categoriaABC = [];

                this.ChipsCategoria.push(item);

                this.ChipsCategoria.map((categoria) => {
                  names.push(categoria.name);
                });

                names.sort();

                names.map((name) => {
                  this.ChipsCategoria.map((categoria) => {
                    if (name == categoria.name) {
                      categoriaABC.push(categoria);
                    }
                  });
                });

                this.ChipsCategoria = categoriaABC;

                this.request = false;
              });
            })
            .catch((err) => {});

          if (this.ChipsCategoria.length > 0) {
            this.$store.dispatch("alert_success_bottom", {
              //Alert
              message: "Cargas totales - " + this.ChipsCategoria.length,
              time: this.time, //Padrao 2000
            });
          }
        }
      }
    },

    handlerPaginator(page, props) {
      this.loading = true;

      console.log(props);
      this.productService = [];

      console.log(page, props);

      ProductsService.getPendingParamPage(
        props.retail,
        props.category,
        [props.country],
        props.brand,
        page,
        props.start_date,
        props.end_date
      )
        .then((result) => {
          var id = 0;
          console.log(result);
          result.data.data.map((item) => {
            id++;

            item.brand_abm = [];
            item.nombre = [];
            item.categoria_abm = [];

            item.id_ = id;

            item.nombre_status = false;

            item.class = "";

            item.feature_1 = [];
            item.feature_2 = [];
            item.feature_3 = [];
            item.feature_4 = [];
            item.feature_5 = [];

            item.options = {
              brand_abm: { suggestions: [], disabled: false },
              name: { suggestions: [], disabled: true },
              categoria_abm: { suggestions: [], disabled: true },
              btn_agregar: { disabled: true },
              features: {
                disabled: true,
                feature_1: [],
                feature_2: [],
                feature_3: [],
                feature_4: [],
                feature_5: [],
              },
            };

            item.buttons = [
              {
                label: "Descartar",
                id: id,
                md5: item.md5_link,
                command: (click) => {
                  this.softDelete(click.item.md5, item.id_);
                },
              },
              {
                label: "Cancelar",
                id: id,
                command: (click) => {
                  this.cancelar(click.item.id);
                },
              },
            ];

            this.productService.push(item); //Passando para props
            console.log(this.productService);
          });
          this.loading = false; //Css propriedades
          this.loadings(false);
        })
        .catch((err) => {});
    },

    showCategoria(val) {
      if (this.request == true) {
      } else {
        if (this.selectedRetail == null) {
          this.$store.dispatch("alert_error", {
            //Alert
            message: "Seleccione el retail primero",
            time: this.time, //Padrao 2000,
          });
        } else if (this.selectedRetail != null) {
          if (this.selectedRetail.length == 0) {
            this.$store.dispatch("alert_error", {
              //Alert
              message: "Seleccione el retail primero",
              time: this.time, //Padrao 2000,
            });
          }
        }
      }
    },

    hideCategoria(val) {
      if (val == this.selectedCategoria) {
        this.selectedCategoria = val;
      } else {
        this.selectedCategoria = val;
        this.selectedBrand = null;
      }
    },

    showBrand() {
      if (this.selectedCategoria == null) {
        this.$store.dispatch("alert_error", {
          //Alert
          message: "Seleccione el categoria primero",
          time: this.time, //Padrao 2000
        });
      } else if (this.selectedCategoria != null) {
        if (this.selectedCategoria.length == 0) {
          this.$store.dispatch("alert_error", {
            //Alert
            message: "Seleccione el categoria primero",
            time: this.time, //Padrao 2000
          });
        }
      }
    },

    hideBrand(val) {
      //Methodo do vue prime
      if (val) {
        this.selectedBrand = val;
      }
    },

    //QUERY BTN

    clearList() {
      //Clear all values

      this.paisSelect = null;
      this.ChipsRetail = [];
      this.ChipsCategoria = [];
      this.ChipsBrand = [];
      this.paisSelect = null;
      this.selectedRetail = null;
      this.selectedCategoria = null;
      this.selectedBrand = null;

      this.productService = [];

      this.widthTable = "auto"; //Css propriedades
      this.collapse = "table-collapsed"; //Css propriedades
    },

    getObtener(value) {
      this.productService = [];
      this.widthTable = "auto";
      this.collapse = "table-collapsed";

      if (!value.country) {
        this.$store.dispatch("alert_error", {
          //Alert
          message: "Seleccione el país primero",
          time: this.time, //Padrao 2000,
        });
      } else if (!value.retail) {
        this.$store.dispatch("alert_error", {
          //Alert
          message: "Seleccione las retail primero",
          time: this.time, //Padrao 2000,
        });
      } else if (value.category || value.category_abm) {
        this.spinner = "spinner-loading";
        this.loading = true;

        //Tratando as variaveis para o request
        value.category = value.category.map((item) => {
          return item.name;
        });

        if (value.brand) {
          value.brand = value.brand.map((item) => {
            return item.name;
          });
        } else {
          value.brand = [];
        }

        value.retail = value.retail.map((item) => {
          return item.retail;
        });

        ProductsService.getPendingCount(
          value.country,
          value.retail,
          value.category,
          value.brand,
          value.start_date,
          value.end_date
        )
          .then((result) => {
            var data = [];
            data.current_page = 1;
            data.first_page_url = env.PENDING + 1;
            data.first_page = 1;
            data.path = env.PENDING;
            data.last_page = result.data.total_pages;
            data.total_pages = result.data.total_pages;
            data.total = result.data.total;

            this.total = data.total = result.data.total;

            data.next_page_url = env.PENDING + (data.current_page + 1);

            if (data.current_page == 1) {
              data.prev_page_url = env.PENDING + data.current_page;
            } else {
              data.prev_page_url = env.PENDING + (data.current_page - 1);
            }

            data.last_page_url = env.PENDING + data.total_pages;

            data.links = [
              {
                active: false,
                label: "pagination.previous",
                url: "",
              },
            ];

            console.log(data.links);

            for (var i = 1; i <= data.total_pages; i++) {
              var bool;
              if (i == 1) {
                bool = true;
              } else {
                bool = false;
              }
              var obj = {
                active: bool,
                label: i,
                url: env.PENDING + i,
              };

              data.links.push(obj);
            }

            var next = {
              active: true,
              label: "pagination.next",
              url: env.PENDING + data.total_pages,
            };

            data.links.push(next);
            this.link = env.PENDING;

            if (data.current_page <= 0) {
              return (data.current_page = 0);
            }
            if (data.current_page > data.total_pages) {
              return (data.current_page = data.total_pages);
            }

            console.log(data.links);

            this.props = {
              country: value.country,
              retail: value.retail,
              category: value.category,
              brand: value.brand,
              start_date: value.start_date,
              end_date: value.end_date,
            };
            this.options = data;
            this.links = data.links;
          })
          .catch((err) => {});

        ProductsService.getPending(
          value.country,
          value.retail,
          value.category,
          value.brand,
          value.start_date,
          value.end_date
        ) //Request
          .then((result) => {
            var id = 0;
            result.data.data.map((item) => {
              id++;

              item.brand_abm = [];
              item.nombre = [];
              item.categoria_abm = [];

              item.id_ = id;

              item.nombre_status = false;

              item.class = "";

              item.feature_1 = [];
              item.feature_2 = [];
              item.feature_3 = [];
              item.feature_4 = [];
              item.feature_5 = [];

              item.options = {
                brand_abm: { suggestions: [], disabled: false },
                name: { suggestions: [], disabled: true },
                categoria_abm: { suggestions: [], disabled: true },
                btn_agregar: { disabled: true },
                features: {
                  disabled: true,
                  feature_1: [],
                  feature_2: [],
                  feature_3: [],
                  feature_4: [],
                  feature_5: [],
                },
              };

              item.buttons = [
                {
                  label: "Descartar",
                  id: item.id_,
                  md5: item.md5_link,
                  command: (click) => {
                    this.softDelete(click.item.md5, item.id_);
                  },
                },
                {
                  label: "Cancelar",
                  id: id,
                  command: (click) => {
                    this.cancelar(click.item.id);
                  },
                },
              ];

              this.productService.push(item); //Passando para props
              
            });
            this.loading = false; //Css propriedades
            this.loadings(false);
          })
          .catch((err) => {});
      } else {
        this.$store.dispatch("alert_error", {
          //Alert
          message: "Seleccione las categorías primero",
          time: this.time, //Padrao 2000
        });
      }
    },

    //BUTTONS
    softDelete(md5, id) {
      console.log(md5);
      this.productService.map((item) => {
        if (item.md5_link == md5) {
          item.class = "removed";
        }
      });

      ProductsService.softDelete(md5)
        .then((result) => {
          this.$store.dispatch("alert_success", {
            //Alert
            message: "Producto desechado",
            time: 3000,
          });
          var idCalc;
          var query = 'brand_'+idCalc;

          console.log(this.$children[1].$refs['brand_'+ (id+1)])

          this.$children[1].$refs['brand_'+ (id+1)][0].focus();

          this.total -= 1;

          this.productService = this.productService.filter(
            (item) => item.md5_link != md5
          );
        })
        .catch((err) => {});

      console.log(this.$refs);
    },

    cancelar(click) {
      this.productService.map((item) => {
        console.log(window.scrollX, window.scrollY);
        //Limpa todos os campos de uma coluna que efetuou o click
        if (item.id == click) {
          item.nombre = null;
          item.area_abm = [];
          item.division_abm = [];
          item.categoria_abm = [];
          item.feature_1_abm = [];
          item.feature_2_abm = [];
          item.feature_3_abm = [];
          item.feature_4_abm = [];
          item.feature_5_abm = [];
          item.brand_abm = [];
          item.options = {
            name: { disabled: true },
            area_abm: { disabled: true },
            division_abm: { values: [], disabled: true },
            categoria_abm: { values: [], disabled: true },
            features: {
              disabled: true,
              feature_1_abm: { values: [] },
              feature_2_abm: { values: [] },
              feature_3_abm: { values: [] },
              feature_4_abm: { values: [] },
              feature_5_abm: { values: [] },
            },
            brand_abm: { disabled: false },
          };
        }
      });
    },

    loadings(boolean) {
      if (boolean == true) {
        this.collapse = "table-collapsed"; //Css propriedades
        this.spinner = "spinner-loading"; //Css propriedades
      } else {
        setTimeout(() => {
          this.collapse = "not-collapsed"; //Css propriedades
          this.spinner = "spinner-not"; //Css propriedades
        }, 1500);
      }
    },

    taxonomizadoFunc(data) {
      this.total -= 1;
      this.productService = data;
    },
  },
};
</script>
<style lang=""></style>
