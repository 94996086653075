<template>
  <div>
    <div>
      <DataTable
        ref="dt"
        :value="Brand"
        dataKey="id"
        :rowClass="rowClass"
        :class="
          ' editable-cells-table datatable-custom datatable-custom-edit mt-3 pl-2 ' +
          collapse
        "
        editMode="cell"
        :filters="filters"
      >
        <template #header>
          <div class="table-header" style="display: flex">
            <h4
              class="p-m-0"
              style="vertical-align: middle; align-self: center"
            ></h4>
            <span class="p-input-icon-left" style="margin-left: auto">
              <i class="pi pi-search" />
              <AutoComplete
                v-model="search"
                class="custom-auto-complete not-icon"
                placeholder="Buscar..."
                @complete="sortableSearch($event)"
                field="Nombre"
              />
            </span>
          </div>
        </template>

        <Column field="id" headerStyle="width: 100px;">
          <template #header>
            <span @click="sortableId" class="p-column-title"> ID </span>
          </template>
        </Column>
        <Column field="name" headerStyle="width: 12rem">
          <template #header>
            <span @click="sortableName" class="p-column-title"> Nombre </span>
          </template>
          <template #body="slotProps">
            <div v-if="slotProps.data.type">
              <InputText
                v-model="slotProps.data[slotProps.column.field]"
                class="custom-dropdown inputtext-custom pl-2"
                :placeholder="slotProps.data.placeHolder"
              />
            </div>
            <div v-else>
              {{ slotProps.data[slotProps.column.field] }}
            </div>
          </template>
          <template #editor="slotProps">
            <InputText
              v-model="slotProps.data[slotProps.column.field]"
              class="custom-dropdown inputtext-custom pl-2"
              :placeholder="slotProps.data.placeHolder"
            />
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <div
              v-if="slotProps.data.type"
              style="text-align: right"
              class="p-mr-2"
            >
              <SplitButton
                label="Save"
                class="custom-split-button"
                :model="slotProps.data.buttons"
                @click="postBrand(slotProps.data.name)"
              ></SplitButton>
            </div>
            <div v-else style="text-align: right" class="p-mr-2">
              <Button
                label="Editar"
                style="width: 160px"
                class="button-custom mr-2"
                @click="editPerId(slotProps.data)"
              ></Button>
              <Button
                label="Borrar"
                style="width: 160px"
                class="button-custom-danger"
                @click="delBrandPerId(slotProps.data.id)"
              ></Button>
            </div>
          </template>

          <template #header>
            <div style="display: flex; justify-content: end; margin-right: 8px">
              <div style="display: flex; align-self: center; margin-right: 15px">
                <Button
                  icon="pi pi-file-excel"
                  class="p-button-rounded p-button-success p-button-outlined btn-green"
                  @click="getTemplate"
                ></Button>
              </div>
              <FileUpload
                name="demo[]"
                icon="pi pi-file-excel"
                class="custom-upload"
                mode="basic"
                :accept="'.xls, .xlsx'"
                @select="errorUpload"
                :multiple="false"
                @error="errorUpload"
                :chooseLabel="'Importar'"
                :customUpload="true"
                @uploader="customUpload"
                :showUploadButton="true"
                ref="upload"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <div :class="'container-custom ' + spinner" style="margin-top: 250px">
      <ProgressSpinner />
    </div>
    <div :class="collapse">
      <Paginator
        :options="options"
        :links="links"
        @nextPage="dataHandler"
        @lastPage="dataHandler"
        @prevPage="dataHandler"
        @firstPage="dataHandler"
        @numberPage="dataHandler"
        @loading="loadings"
        :props="propsPaginator"
        :paramsAbm="true"
        :total="total"
      ></Paginator>
    </div>
  </div>
</template>
<script>
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import Column from "primevue/column";
import { BrandService } from "../../services/BrandService";
import InputText from "primevue/inputtext";
import Rating from "primevue/rating";
import Toolbar from "primevue/toolbar";
import FileUpload from "primevue/fileupload";
import Dialog from "./../../components/Dialogs/Dialog.vue";
import DialogDanger from "./../../components/Dialogs/DialogDanger.vue";
import Paginator from "./../../components/Paginator.vue";
import ProgressSpinner from "primevue/progressspinner";
import SplitButton from "primevue/splitbutton";
import AutoComplete from "primevue/autocomplete";
import env from "./../../env";
import { excelService } from "./../../services/Excel/excelService.js";

import "primeflex/primeflex.css";
export default {
  components: {
    DataTable,
    Column,
    Button,
    Rating,
    InputText,
    Toolbar,
    FileUpload,
    Dialog,
    DialogDanger,
    SplitButton,
    Paginator,
    ProgressSpinner,
    AutoComplete,
  },
  data() {
    return {
      //Area
      Brand: [],
      selectedBrand: null,
      filters: {},

      currentPage: 1,

      search: null,

      //Default
      default: null,
      buttons: null,

      //Inputs
      inputPost: null,

      propsPaginator: null,

      options: null,
      links: null,

      collapse: "table-collapsed",
      spinner: "spinner-loading",

      sortable: "id",
      asc: -1,

      Linha: 0,

      total: 0,
    };
  },
  created() {
    //Definindo os arrays para poder acessas methods pelos arrays
    this.default = {
      id: "ID",
      name: null,
      placeHolder: "Completa el nombre",
      type: "INCREMENT",
      buttons: [
        {
          label: "Clear",
          command: () => {
            this.clearInsertPerline("ID");
          },
        },
      ],
    };

    this.buttons = [
      {
        label: "Borrar",
        command: () => {},
      },
    ];

    //Renderiza os dados
    this.dataHandler();
  },

  watch: {
    search(newVal, oldVal) {
      if (newVal == "") {
        this.dataHandler();
      }
    },

    Brand(newVal, oldVal) {
      console.log(newVal);
      newVal.map((item) => {
        this.Linha += 1;
        if (item.type != "INCREMENT") {
          if (this.Linha % 2 == 0) {
            item.class = " linea_gris";
          } else {
            item.class = " linea_white";
          }
        }
      });
    },
  },

  methods: {
    //Renderiza os dados
    dataHandler(data, currentPage, name) {
      if (localStorage.http_token == null) {
        localStorage.clear();
        this.$router.push({ path: "/login" });
      } else if (data) {
        this.Brand = [];
        this.currentPage = currentPage;

        this.Brand.push(this.default);
        data.data.map((item) => {
          this.Brand.push(item);
        });
        this.clearInsertPerline("ID");
        this.loadings(false);
      } else {
        BrandService.getAllperPage(
          name,
          this.sortable,
          this.asc,
          this.currentPage
        )
          .then((result) => {
            if (result.request.status == 200) {
              this.Brand = [];
              this.Brand.push(this.default);

              this.total = result.data.total;

              result.data.data.map((item) => {
                this.Brand.push(item);
              });

              this.clearInsertPerline("ID");

              var data = [];

              //Data.links - Referente a paginação

              data.links = [
                {
                  active: false,
                  label: "pagination.previous",
                  url: "",
                },
              ];

              for (var i = 1; i <= result.data.total_pages; i++) {
                var bool;
                if (i == 1) {
                  bool = true;
                } else {
                  bool = false;
                }
                var obj = {
                  active: bool,
                  label: i,
                  url: env.API + "?page=" + i,
                };

                data.links.push(obj);
              }

              //Data.links - Referente a paginação

              var next = {
                active: true,
                label: "pagination.next",
                url: env.API + "?page=" + result.data.total_pages,
              };

              data.links.push(next);

              result.data.links = data.links;

              this.options = result.data;
              this.links = data.links;

              this.propsPaginator = {
                name: name,
                order_by: this.sortable,
                asc: this.asc,
              };

              this.collapse = "not-collapsed"; //Css propriedades
              this.spinner = "spinner-not"; //Css propriedades
            } else if (result.request.status != 200) {
              var message = result.response.data.message;
              this.$store.dispatch("alert_error", {
                message: message,
                time: 3000,
              });
            } else {
              var message = "Internal error";
              this.$store.dispatch("alert_error", {
                message: message,
                time: 3000,
              });
            }
          })
          .catch((err) => {
            var message = "Internal error";
            this.$store.dispatch("alert_error", {
              message: message,
              time: 3000,
            });
          });
      }
    },

    //Clear primeira linha
    clearInsertPerline(id) {
      this.Brand.map((item) => {
        if (item.id == id) {
          item.name = null;
          return item;
        }
      });
    },

    rowClass(data){
      return data.class;
    },

    //Post Brand
    postBrand(inputText) {
      if (inputText == null || inputText == "") {
        this.$store.dispatch("alert_error", {
          message: "El nombre no se llenó",
          time: 1500,
        });

        this.clearInsertPerline("ID");
      } else {
        BrandService.postBrand(inputText)
          .then((result) => {
            if (result.request.status == 200) {
              this.dataHandler();

              //Alert
              const message = result.data.success;
              const capitalized = message[0].toUpperCase() + message.substr(1);

              this.$store.dispatch("alert_success", {
                message: capitalized,
                time: 2000,
              });
            } else if (result.request.status != 200) {
              var message = result.response.data.errors.name[0];
              this.$store.dispatch("alert_error", {
                message: message,
                time: 3000,
              });
            } else {
              var message = "Internal error";
              this.$store.dispatch("alert_error", {
                message: message,
                time: 3000,
              });
            }
          })
          .catch((err) => {
            var message = "Internal error";
            this.$store.dispatch("alert_error", {
              message: message,
              time: 3000,
            });
          });
      }
    },

    //Delete area
    delBrandPerId(id) {
      BrandService.delBrandId(id)
        .then((result) => {
          if (result.request.status == 200) {
            this.$store.dispatch("alert_error", {
              message: "Eliminado con éxito",
              time: 1500,
            });

            this.total -= 1;

            this.Brand = this.Brand.filter((items) => items.id != id);
          } else if (result.request.status != 200) {
            var error = result.response.data.errors.delete[0];
            this.$store.dispatch("alert_error", {
              message: error,
              time: 3000,
            });
          } else {
            var message = "Internal error";
            this.$store.dispatch("alert_error", {
              message: message,
              time: 3000,
            });
          }
        })
        .catch((err) => {
          var message = "Internal error";
          this.$store.dispatch("alert_error", {
            message: message,
            time: 3000,
          });
        });
    },

    editPerId(data) {
      var id = data.id;
      var name = data.name;

      BrandService.updateBrandId(id, name)
        .then((result) => {
          if (result.request.status == 200) {
            this.$store.dispatch("alert_success", {
              message: "Actualizado exitosamente",
              time: 2000,
            });
          } else if (result.request.status != 200) {
            var message = result.response.data.errors.name[0];
            this.$store.dispatch("alert_error", {
              message: message,
              time: 3000,
            });
          } else {
            var message = "Internal error";
            this.$store.dispatch("alert_error", {
              message: message,
              time: 3000,
            });
          }
        })
        .catch((err) => {
          var message = "Internal error";
          this.$store.dispatch("alert_error", {
            message: message,
            time: 3000,
          });
        });
    },

    loadings(boolean) {
      if (boolean == true) {
        this.collapse = "table-collapsed"; //Css propriedades
        this.spinner = "spinner-loading"; //Css propriedades
      } else {
        setTimeout(() => {
          this.collapse = "not-collapsed"; //Css propriedades
          this.spinner = "spinner-not"; //Css propriedades
        }, 1500);
      }
    },

    sortableName() {
      this.sortable = "name";
      if (this.asc == 1) {
        this.asc = -1;
      } else {
        this.asc = 1;
      }

      this.dataHandler();
    },

    sortableSearch(e) {
      this.dataHandler(null, null, e.query);
    },

    customUpload(event) {
      var files = event.files[0];
      excelService
        .postExcel("brands", files)
        .then((result) => {
          this.dataHandler();
          this.$refs.upload.clear();
          this.$store.dispatch("alert_success", {
            message: `Cargado correctamente`,
            time: 3000,
          });
        })
        .catch((err) => {});
    },

    errorUpload(event) {
      if(event){
        if(event.files.length == 0){
          this.$store.dispatch('alert_error', {message: `Solo aceptamos archivos con las extensiones .xls, .xlsx`, time:3000})
        }
      }else {
        console.log('error');
      }
    },

    getTemplate(){
      excelService.getExcel('brands');
    },
    
    sortableId() {
      this.sortable = "id";
      if (this.asc == 1) {
        this.asc = -1;
      } else {
        this.asc = 1;
      }

      this.dataHandler();
    },
  },
};
</script>
<style>
</style>