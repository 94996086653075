<template>
  <div>
    <div>
      <DataTable
        ref="dt"
        :value="Divisions"
        dataKey="id"
        :rowClass="rowClass"
        :class="
          ' editable-cells-table datatable-custom datatable-custom-edit mt-3 pl-2 ' +
          collapse
        "
        editMode="cell"
        :filters="filters"
      >
        <template #header>
          <div class="table-header" style="display: flex">
            <h4
              class="p-m-0"
              style="vertical-align: middle; align-self: center"
            ></h4>
            <span class="p-input-icon-left" style="margin-left: auto">
              <i class="pi pi-search" />
              <AutoComplete
                v-model="search"
                class="custom-auto-complete not-icon"
                placeholder="Buscar..."
                @complete="sortableSearch($event)"
                field="Nombre"
              />
            </span>
          </div>
        </template>

        <Column field="id" headerStyle="width: 100px;">
          <template #header>
            <span @click="sortableId" class="p-column-title"> ID </span>
          </template>
        </Column>
        <Column field="name" :sortable="false" headerStyle="width: 12rem">
          <template #header>
            <span @click="sortableName" class="p-column-title"> Nombre </span>
          </template>
          <template #body="slotProps">
            <div v-if="slotProps.data.type">
              <InputText
                v-model="slotProps.data[slotProps.column.field]"
                class="custom-dropdown inputtext-custom pl-2"
                :placeholder="slotProps.data.placeHolder"
                :disabled="true"
              />
            </div>
            <div v-else>
              {{ slotProps.data[slotProps.column.field] }}
            </div>
          </template>

          <template #editor="slotProps">
            <InputText
              v-model="slotProps.data[slotProps.column.field]"
              class="custom-dropdown inputtext-custom pl-2"
              :placeholder="slotProps.data.placeHolder"
            />
          </template>
        </Column>
        <Column
          field="area.name"
          header="Área"
          :sortable="false"
          headerStyle="width: 12rem"
        >
          <template #body="slotProps">
            <div v-if="slotProps.data.type">
              <AutoComplete
                v-model="selectedArea"
                :suggestions="areas"
                @complete="searchArea($event)"
                field="name"
                placeholder="Área"
                class="custom-auto-complete auto-complete-no-box"
                :dropdown="true"
                :disabled="true"
              />
            </div>
            <div v-else>
              <div v-if="slotProps.data.area.name">
                {{ slotProps.data.area.name }}
              </div>
              <div v-else>
                Seleccione un área
              </div>
            </div>
          </template>
          <template #editor="slotProps">
            <div v-if="slotProps.data.type">
              <AutoComplete
                v-model="selectedArea"
                :suggestions="areas"
                @complete="searchArea($event)"
                field="name"
                placeholder="Área"
                class="custom-auto-complete auto-complete-no-box"
                :dropdown="true"
              />
            </div>
            <div v-else>
              <AutoComplete
                v-model="slotProps.data.area"
                :suggestions="areas"
                @complete="searchArea($event)"
                field="name"
                placeholder="Área"
                class="custom-auto-complete auto-complete-no-box"
                :dropdown="true"
              />
            </div>
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <div
              v-if="slotProps.data.type"
              style="text-align: right"
              class="p-mr-2"
            >
              <SplitButton
                label="Save"
                class="custom-split-button"
                :model="slotProps.data.buttons"
                @click="postDivision(slotProps.data.name)"
              ></SplitButton>
            </div>
            <div v-else style="text-align: right" class="p-mr-2">
              <Button
                label="Editar"
                style="width: 160px"
                class="button-custom mr-2"
                @click="editPerId(slotProps.data)"
              ></Button>
              <Button
                label="Borrar"
                style="width: 160px"
                class="button-custom-danger"
                @click="delDivisionPerId(slotProps.data.id)"
              ></Button>
            </div>
          </template>
          <template #header>
            <div style="display: flex; justify-content: end; margin-right: 8px">
              <div
                style="display: flex; align-self: center; margin-right: 15px"
              >
                <Button
                  icon="pi pi-file-excel"
                  class="
                    p-button-rounded p-button-success p-button-outlined
                    btn-green
                  "
                  @click="getTemplate"
                ></Button>
              </div>
              <FileUpload
                name="demo[]"
                icon="pi pi-file-excel"
                class="custom-upload"
                mode="basic"
                :chooseLabel="'Importar'"
                :accept="'.xls, .xlsx'"
                @select="errorUpload"
                :multiple="false"
                @error="errorUpload"
                :customUpload="true"
                @uploader="customUpload"
                :showUploadButton="true"
                ref="upload"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <div :class="'container-custom ' + spinner" style="margin-top: 250px">
      <ProgressSpinner />
    </div>
    <div :class="collapse">
      <Paginator
        :options="options"
        :links="links"
        @nextPage="dataHandler"
        @lastPage="dataHandler"
        @prevPage="dataHandler"
        @firstPage="dataHandler"
        @numberPage="dataHandler"
        @loading="loadings"
        :props="propsPaginator"
        :paramsAbm="true"
        :total="total"
      ></Paginator>
    </div>
  </div>
</template>
<script>
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import Column from "primevue/column";
import { DivisionService } from "../../services/DivisionService";
import { AreaService } from "../../services/AreaService";
import InputText from "primevue/inputtext";
import Rating from "primevue/rating";
import Toolbar from "primevue/toolbar";
import FileUpload from "primevue/fileupload";
import SplitButton from "primevue/splitbutton";
import Dropdown from "primevue/dropdown";
import Paginator from "./../../components/Paginator.vue";
import ProgressSpinner from "primevue/progressspinner";
import AutoComplete from "primevue/autocomplete";
import env from "./../../env";
import { excelService } from "./../../services/Excel/excelService.js";

import "primeflex/primeflex.css";
export default {
  components: {
    DataTable,
    Column,
    Button,
    Rating,
    InputText,
    Toolbar,
    FileUpload,
    Dropdown,
    SplitButton,
    Paginator,
    ProgressSpinner,
    AutoComplete,
  },
  data() {
    return {
      //Divisions
      Divisions: [],

      filters: {},

      currentPage: 1,

      //Areas
      areas: [],
      selectedArea: null,

      //Defaults
      buttons: null,
      default: null,

      //Inputs
      inputPost: null,

      options: null,
      links: null,

      search: null,

      propsPaginator: null,

      collapse: "table-collapsed",
      spinner: "spinner-loading",

      sortable: "id",
      asc: -1,

      Linha: 0,

      total: 0,
    };
  },
  created() {
    this.dataHandler();

    this.default = {
      id: "ID",
      name: null,
      placeHolder: "Completa el nombre",
      type: "INCREMENT",
      area: {
        name: null,
      },
      buttons: [
        {
          label: "Clear",
          command: () => {
            this.clearInsertPerline("ID");
          },
        },
      ],
    };

    this.buttons = [
      {
        label: "Borrar",
        command: () => {},
      },
    ];
  },

  watch: {
    search(newVal, oldVal) {
      if (newVal == "") {
        this.dataHandler();
      }
    },

    Divisions(newVal, oldVal) {
      newVal.map((item) => {
        this.Linha += 1;
        if (item.type != "INCREMENT") {
          if (this.Linha % 2 == 0) {
            item.class = " linea_gris";
          } else {
            item.class = " linea_white";
          }
        }
      });
    }
  },

  methods: {
    dataHandler(data, currentPage, name) {
      if (localStorage.http_token == null) {
        localStorage.clear();
        this.$router.push({ path: "/login" });
      } else if (data) {
        this.Divisions = [];
        this.currentPage = currentPage;
        this.Divisions.push(this.default);
        data.data.map((item) => {
          this.Divisions.push(item);
        });
        this.loadings(false);
      } else {
        //GET - DIVISION
        DivisionService.getAllperPage(
          name,
          this.sortable,
          this.asc,
          this.currentPage
        )
          .then((result) => {
            if (result.request.status == 200) {
              this.total = result.data.total;
              this.Divisions = [];
              this.Divisions.push(this.default);

              result.data.data.map((item) => {
                this.Divisions.push(item);
              });

              var data = [];

              //Data.links - Referente a paginação

              data.links = [
                {
                  active: false,
                  label: "pagination.previous",
                  url: "",
                },
              ];

              for (var i = 1; i <= result.data.total_pages; i++) {
                var bool;
                if (i == 1) {
                  bool = true;
                } else {
                  bool = false;
                }
                var obj = {
                  active: bool,
                  label: i,
                  url: env.API + "?page=" + i,
                };

                data.links.push(obj);
              }

              var next = {
                active: true,
                label: "pagination.next",
                url: env.API + "?page=" + result.data.total_pages,
              };

              data.links.push(next);

              //Data.links - Referente a paginação

              result.data.links = data.links;

              this.options = result.data;
              this.links = data.links;

              this.propsPaginator = {
                name: name,
                order_by: this.sortable,
                asc: this.asc,
              };

              this.loadings(false);
            } else if (result.request.status != 200) {
              var message = result.response.data.errors.name[0];
              this.$store.dispatch("alert_error", {
                message: message,
                time: 3000,
              });

              if (result.response.data.errors.id != null) {
                var message2 = result.response.data.errors.id[0];
                this.$store.dispatch("alert_error", {
                  message: message2,
                  time: 3000,
                });
              }
            } else {
              var message = "Internal error";
              this.$store.dispatch("alert_error", {
                message: message,
                time: 3000,
              });
            }
          })
          .catch((err) => {
            var message = "Internal error";
            this.$store.dispatch("alert_error", {
              message: message,
              time: 3000,
            });
          });
        //GET - AREA
        AreaService.getAll()
          .then((result) => {
            if (result.request.status == 200) {
              this.areas = result.data.data;
            } else if (result.request.status != 200) {
              var message = result.response.data.message;
              this.$store.dispatch("alert_error", {
                message: message,
                time: 3000,
              });
            } else {
              var message = "Internal error";
              this.$store.dispatch("alert_error", {
                message: message,
                time: 3000,
              });
            }
          })
          .catch((err) => {
            var message = "Internal error";
            this.$store.dispatch("alert_error", {
              message: message,
              time: 3000,
            });
          });
      }
    },
    postDivision(name, area) {
      var inputText = name;
      var area_id = null;
      if (inputText == null || inputText == "") {
        this.$store.dispatch("alert_error", {
          message: "El nombre no se llenó",
          time: 1500,
        });
      } else if (this.selectedArea == null || !this.selectedArea.id) {
        this.$store.dispatch("alert_error", {
          message: "Seleccione un área",
          time: 1500,
        });
      } else {
        area_id = this.selectedArea.id;
        DivisionService.postDivision(inputText, area_id)
          .then((result) => {
            if (result.request.status == 200) {
              this.dataHandler();

              //Alert
              const message = result.data.success;
              const capitalized = message[0].toUpperCase() + message.substr(1);

              this.$store.dispatch("alert_success", {
                message: capitalized,
                time: 2000,
              });
            } else if (result.request.status != 200) {
              var message = result.response.data.errors.name[0];

              this.$store.dispatch("alert_error", {
                message: message,
                time: 3000,
              });
            } else {
              var message = "Internal error";
              this.$store.dispatch("alert_error", {
                message: message,
                time: 3000,
              });
            }
          })
          .catch((err) => {
            var message = "Internal error";
            this.$store.dispatch("alert_error", {
              message: message,
              time: 3000,
            });
          });
      }
    },

    rowClass(data) {
      return data.class;
    },

    delDivisionPerId(id) {
      //Implementar
      DivisionService.delDivisionId(id)
        .then((result) => {
          if (result.request.status == 200) {
            this.Divisions = this.Divisions.filter((item) => item.id != id);
            this.total -= 1; 
            this.$store.dispatch("alert_error", {
              message: "Eliminado con éxito",
              time: 1500,
            });
          } else if (result.request.status != 200) {
            var error = result.response.data.errors.delete[0];
            this.$store.dispatch("alert_error", {
              message: error,
              time: 3000,
            });
          } else {
            var message = "Internal error";
            this.$store.dispatch("alert_error", {
              message: message,
              time: 3000,
            });
          }
        })
        .catch((err) => {
          var message = "Internal error";
          this.$store.dispatch("alert_error", {
            message: message,
            time: 3000,
          });
        });
    },

    editPerId(data) {
      var id = data.id;
      var name = data.name;
      
      if (data.area.id) {
        var area_id = data.area.id;

        DivisionService.updateDivisionId(id, name, area_id)
          .then((result) => {
            if (result.request.status == 200) {
              this.$store.dispatch("alert_success", {
                message: "Actualizado exitosamente",
                time: 2000,
              });
            } else if (result.request.status != 200) {
              var message = result.response.data.errors.name[0];
              this.$store.dispatch("alert_error", {
                message: message,
                time: 3000,
              });
            } else {
              var message = "Internal error";
              this.$store.dispatch("alert_error", {
                message: message,
                time: 3000,
              });
            }
          })
          .catch((err) => {
            var message = "Internal error";
            this.$store.dispatch("alert_error", {
              message: message,
              time: 3000,
            });
          });
      }else{
        this.$store.dispatch("alert_error", {
          message: "Seleccione un área",
          time: 1500,
        });
      }
    },

    //Clear primeira linha
    clearInsertPerline(id) {
      this.Divisions.map((item) => {
        if (item.id == id) {
          item.name = null;
          this.selectedArea = null;
          return item;
        }
      });
    },

    loadings(boolean) {
      if (boolean == true) {
        this.collapse = "table-collapsed"; //Css propriedades
        this.spinner = "spinner-loading"; //Css propriedades
      } else {
        setTimeout(() => {
          this.collapse = "not-collapsed"; //Css propriedades
          this.spinner = "spinner-not"; //Css propriedades
        }, 1500);
      }
    },

    sortableId() {
      this.sortable = "id";
      if (this.asc == 1) {
        this.asc = -1;
      } else {
        this.asc = 1;
      }

      this.dataHandler();
    },

    sortableName() {
      this.sortable = "name";
      if (this.asc == 1) {
        this.asc = -1;
      } else {
        this.asc = 1;
      }

      this.dataHandler();
    },

    sortableSearch(e) {
      this.dataHandler(null, null, e.query);
    },

    customUpload(event) {
      var files = event.files[0];
      excelService
        .postExcel("divisions", files)
        .then((result) => {
          this.dataHandler();
          this.$refs.upload.clear();
          this.$store.dispatch("alert_success", {
            message: `Cargado correctamente`,
            time: 3000,
          });
        })
        .catch((err) => {});
    },

    errorUpload(event) {
      if (event) {
        if (event.files.length == 0) {
          this.$store.dispatch("alert_error", {
            message: `Solo aceptamos archivos con las extensiones .xls, .xlsx`,
            time: 3000,
          });
        }
      } else {
        console.log("error");
      }
    },

    getTemplate() {
      excelService.getExcel("divisions");
    },

    searchArea(event) {
      var name = event.query;
      AreaService.getAutocomplete(name)
        .then((result) => {
          this.areas = result.data;
        })
        .catch((err) => {});
    },
  },
};
</script>
<style>
</style>