<template>
  <div class="p-mt-4">
    {{ propsToVar }}
    <div style="display: flex" class="mb-2">
      <Dropdown
        v-if="paisList"
        class="custom-dropdown header-closed"
        v-model="paisSelect"
        style="margin-left: 10px; margin-right: 10px; width: 75%; max-width: 371.08px"
        :options="paisList"
        @input="$emit('InputPais', paisSelect)"
        @hide="hidePais"
        @change="guard()"
        w
        optionLabel="name"
        placeholder="País"
        :disabled="disabledOpt.pais"
      />
      <MultiSelect
        v-if="chipsRetail"
        v-model="selectedRetail"
        class="custom-multiselect"
        :options="retail"
        style="margin-left: 10px; margin-right: 10px; width: 75%; max-width: 371.08px"
        optionLabel="name"
        placeholder="Retail"
        display="chip"
        @show="$emit('ShowRetail', selectedRetail)"
        @hide="hideRetail"
        :disabled="disabledOpt.retail"
      />

      <Button
        class="button-custom mr-2"
        style="margin-left: 10px; margin-right: 10px; width: 30%"
        label="Obtener"
        @click="getQuery"
      ></Button>
      <Button
        class="button-custom-limpiar"
        style="margin-left: 10px; margin-right: 10px; width: 30%"
        label="LIMPIAR"
        v-on:click="clearCampos"
      ></Button>
    </div>
    <div style="display: flex; justify-content: center">
      <AutoComplete
        v-if="chipsArea"
        v-model="selectedArea"
        :suggestions="area"
        :disabled="disabledOpt.area"
        style="margin-left: 10px; max-width: 259px"
        @complete="searchArea($event)"
        @item-select="selectArea($event)"
        field="name"
        placeholder="Area"
        class="
          custom-multiselect custom-auto-complete
          disabled
          auto-complete-taxonomizado
          no-border
        "
        :dropdown="true"
      />

      <AutoComplete
        v-if="chipsDivision"
        v-model="selectedDivision"
        :suggestions="division"
        :disabled="disabledOpt.division"
        style="margin-left: 10px; max-width: 259px"
        @complete="searchDivision($event)"
        @item-select="selectDivision($event)"
        field="name"
        placeholder="Division"
        class="
          custom-multiselect custom-auto-complete
          disabled
          auto-complete-taxonomizado
          no-border
        "
        :dropdown="true"
      />

      <AutoComplete
        v-if="chipsCategoria"
        v-model="selectedCategoria"
        :suggestions="categoria"
        :disabled="disabledOpt.categoria"
        multiple
        style="margin-left: 10px; max-width: 259px"
        @complete="searchCategoria($event)"
        field="name"
        placeholder="Categoria"
        class="
          custom-multiselect custom-auto-complete
          disabled
          fix-button
          auto-complete-taxonomizado
          no-border
        "
        :dropdown="true"
      />

      <AutoComplete
        v-if="chipsBrand"
        v-model="selectedBrand"
        :suggestions="brand"
        :disabled="disabledOpt.brand"
        multiple
        style="margin-left: 10px; max-width: 259px"
        @complete="searchBrand($event)"
        field="name"
        placeholder="Brands"
        class="
          custom-multiselect custom-auto-complete
          disabled
          fix-button
          auto-complete-taxonomizado
          no-border
        "
        :dropdown="true"
      />
    </div>
    <div class="mt-3" style="text-align: end"></div>
  </div>
</template>
<script>
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Button from "primevue/button";
import AutoComplete from "primevue/autocomplete";
import { BrandService } from "../../services/BrandService";
import { AreaService } from "../../services/AreaService";
import { DivisionService } from "../../services/DivisionService";
import { CategoriaServiceABM } from "../../services/CategoriaService";

export default {
  components: {
    Dropdown,
    MultiSelect,
    Button,
    AutoComplete,
  },

  data() {
    return {
      selectedDivision: null,
      selectedArea: null,
      selectedBrand: null,
      selectedCategoria: null,
      paisSelect: null,
      selectedRetail: null,

      retail: [],

      disabledOpt: {
        pais: false,
        retail: true,
        area: false,
        division: true,
        categoria: true,
        brand: false,
      },

      area: [], //ABM
      division: [], //ABM
      categoria: [], //ABM
      brand: [], //ABM
    };
  },

  props: [
    "paisList",
    "chipsRetail",
    "chipsArea",
    "chipsDivision",
    "chipsCategoria",
    "chipsBrand",
    "width",
  ],

  computed: {
    propsToVar() {
      if (this.chipsRetail != null) {
        this.retail = this.chipsRetail;
      }

      if (this.chipsArea) {
        this.area = this.chipsArea;
      }

      if (this.chipsDivision) {
        this.division = this.chipsDivision;
      }

      if (this.chipsCategoria) {
        this.categoria = this.chipsCategoria;
      }

      if (this.chipsBrand) {
        this.brand = this.chipsBrand;
      }
    },
  },

  methods: {
    hidePais() {
      this.$emit("ShowPais", this.paisSelect);
      if (this.paisSelect == null) {
        this.selectedRetail = null;
        this.retail = [];
        this.brand = [];
      } else {
        this.disabledOpt.retail = false;
      }
    },
    hideRetail() {
      this.$emit("HideRetail", this.selectedRetail);
    },

    searchArea(event) {
      var name = event.query;

      this.disabledOpt.division = true;
      this.disabledOpt.categoria = true;

      this.selectedDivision = [];
      this.selectedCategoria = [];

      AreaService.getAutocomplete(name)
        .then((result) => {
          console.log(result.data);
          this.area = result.data;
        })
        .catch((err) => {});
    },

    selectArea(event) {
      this.disabledOpt.division = false;
    },

    searchDivision(event) {
      var name = event.query;
      var area_id = this.selectedArea.id;

      this.disabledOpt.categoria = true;

      this.selectedCategoria = [];

      console.log(this.area);

      if (area_id != null) {
        DivisionService.getAutocomplete(name, area_id)
          .then((result) => {
            this.division = result.data;
          })
          .catch((err) => {});
      }
    },

    selectDivision(event) {
      this.disabledOpt.categoria = false;
    },

    searchCategoria(event) {
      var name = event.query;
      var division_id = this.selectedDivision.id;

      if (division_id != null) {
        CategoriaServiceABM.getAutocomplete(name, division_id)
          .then((result) => {
            this.categoria = result.data;
          })
          .catch((err) => {});
      }
    },

    searchBrand(event) {
      var name = event.query;

      BrandService.getAutocomplete(name)
        .then((result) => {
          this.brand = result.data;
        })
        .catch((err) => {});
    },

    getQuery() {
      var query = [];

      //Country
      if (this.paisSelect != null) {
        query.country = this.paisSelect.country;
      }

      //Retail
      if (this.selectedRetail != null) {
        query.retail = this.selectedRetail;
      }

      //Category
      if (this.selectedCategoria != null) {
        query.category = this.selectedCategoria;
      }

      //Brand ou category_abm
      if (this.selectedBrand != null) {
        query.brand = this.selectedBrand;
      }

      if (this.selectedArea != null) {
        query.area = this.selectedArea;
      }

      if (this.selectedDivision != null) {
        query.division = this.selectedDivision;
      }

      this.$emit("getObtener", query);
    },

    guard() {
      this.selectedRetail = null;
    },

    clearCampos() {
      this.$emit("ClickClear");
      this.selectedCategoria = null;
      this.selectedRetail = null;
      this.selectedBrand = null;
      this.paisSelect = null;
      this.selectedArea = null;
      this.selectedDivision = null;

      this.disabledOpt = {
        pais: false,
        retail: true,
        area: false,
        division: true,
        categoria: true,
        brand: false,
      };

      if (this.chipsRetail) {
        this.retail = [];
      }

      if (this.chipsArea) {
        this.area = [];
      }

      if (this.chipsDivision) {
        this.division = [];
      }

      if (this.chipsCategoria) {
        this.categoria = [];
      }

      if (this.chipsBrand) {
        this.brand = [];
      }
    },
  },
};
</script>
<style lang="">
</style>