<template>
  <div>
    <div style="display: flex" class="p-mt-4">
      {{ propsToVar }}
      <Dropdown
        v-if="paisList"
        class="custom-dropdown mr-2"
        :style="'max-width:' + width"
        v-model="paisSelect"
        :options="paisList"
        @input="$emit('InputPais', paisSelect)"
        @hide="hidePais"
        optionLabel="name"
        placeholder="País"
        :disabled="optDisabled.pais"
      />

      <MultiSelect
        v-if="chipsRetail"
        v-model="selectedRetail"
        class="custom-multiselect mr-2"
        :style="'max-width:' + width"
        :options="retail"
        optionLabel="name"
        placeholder="Retail"
        display="chip"
        @show="showRetail"
        @hide="hideRetail"
        :disabled="optDisabled.retail"
      />
      <MultiSelect
        v-if="type != 'comparativo' && type != 'historico' && chipsCategoria"
        v-model="selectedCategoria"
        class="custom-multiselect mr-2"
        :style="'max-width:' + width"
        :options="categoria"
        optionLabel="name"
        placeholder="Categoria"
        display="chip"
        @show="$emit('ShowCategoria', selectedCategoria)"
        @hide="hideCategoria"
        :disabled="optDisabled.categoria"
      />

      <AutoComplete
        v-if="type != 'comparativo' && chipsBrand"
        v-model="selectedBrand"
        :suggestions="brand"
        :dropdown="true"
        :disabled="disabled"
        :style="'max-width:' + width"
        multiple
        @complete="searchBrand($event)"
        field="name"
        placeholder="Brands"
        class="custom-multiselect custom-auto-complete disabled no-border fix-button"
      />

      <Button
        v-if="type != 'comparativo' && type != 'historico'"
        class="button-custom mr-2"
        style="width: 50%; max-width: 200px"
        :label="btnLeftLabel"
        @click="getQuery"
      ></Button>
      <Button
        v-if="type != 'comparativo' && type != 'historico'"
        class="button-custom-limpiar"
        style="width: 50%; max-width: 200px"
        label="LIMPIAR"
        v-on:click="clearCampos"
      ></Button>
    </div>
    <div v-if="type === 'pendiente'" class="pt-3">
      <Calendar
        v-model="dateDesde"
        class="custom-multiselect custom-auto-complete custom-calendar"
        placeholder="Visto por última vez"
        style="max-width: 185px"
        :showIcon="true"
        :disabledDates="invalidDates"
        @date-select="dateDesdeSelect"
      />

      <Calendar
        v-model="dateHasta"
        class="custom-multiselect custom-auto-complete custom-calendar"
        placeholder="Fecha hasta"
        style="max-width: 185px"
        :showIcon="true"
        :disabledDates="invalidDates"
        :disabled="datePicker2Disabled"
        @date-select="dateHastaSelect"
      />
      <Button
        class="button-custom-limpiar"
        style="width: 50%; max-width: 185px"
        label="Limpiar Fechas"
        v-on:click="clearDates"
      ></Button>
    </div>
  </div>
</template>
<script>
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import Button from "primevue/button";
import AutoComplete from "primevue/autocomplete";
import { OldBrandService } from "./../../services/Taxonomia/OldBrandService";
import { BrandService } from "./../../services/BrandService";
import Calendar from "primevue/calendar";
import { AreaService } from "../../services/AreaService";
import { DivisionService } from "../../services/DivisionService";
import { CategoriaServiceABM } from "../../services/CategoriaService";

export default {
  components: {
    Dropdown,
    MultiSelect,
    Button,
    AutoComplete,
    Calendar,
  },
  data() {
    return {
      dateDesde: null,
      dateHasta: null,
      datePicker2Disabled: true,
      
      selectedDivision: null,
      selectedArea: null,
      selectedBrand: null,
      selectedCategoria: null,
      paisSelect: null,
      selectedRetail: null,
      selectedRetailShow: null,
      selectedCategoria_abm: null,
      selectedBrand_abm: null,

      optDisabled: {
        pais: false,
        retail: true,
        area: false,
        division: true,
        categoria: true,
      },

      retail: [],
      area: [],
      division: [],
      categoria: [],

      datos: [],

      brand: [],

      brand_abm: [],

      datePicker: null,

      categoria_abm: [],

      selectedPrice: null,

      dateHoy: null,

      priceList: [
        { name: "Precio Lista", id: 1 },
        { name: "Precio Oferta", id: 2 },
        { name: "Precio Extra", id: 3 },
      ],
      invalidDates: null,
      disabled: true,
    };
  },

  props: [
    "paisList",
    "chipsRetail",
    "chipsArea",
    "chipsDivision",
    "chipsCategoria",
    "chipsBrand",
    "width",
    "type",
    "chipsCategoria_Abm",
    "btnLeftLabel",
  ],

  computed: {
    propsToVar() {
      if (this.chipsRetail != null) {
        this.retail = this.chipsRetail;
      }

      if (this.chipsArea) {
        this.area = this.chipsArea;
      }

      if (this.chipsDivision) {
        this.division = this.chipsDivision;
      }

      if (this.chipsCategoria) {
        this.categoria = this.chipsCategoria;
      }

      if (this.chipsBrand) {
        this.brand = this.chipsBrand;
      }

      if (this.chipsCategoria_Abm) {
        this.categoria_abm = this.chipsCategoria_Abm;
      }
    },
  },

  created() {
    let today = new Date();

    var dia = 0;
    this.invalidDates = [];

    this.dateHoy = new Date();
    

    for (var i = 1; dia <= 31; i++) {
      dia += 1;
      let invalidDate = new Date();
      invalidDate.setDate(today.getDate() + dia);
      this.invalidDates.push(invalidDate);
    }

    var diaDefault = today.getUTCDate() + 5;

    if (this.type == "historico" || this.type == "comparativo") {
      BrandService.getAll()
        .then((result) => {
          this.brand_abm = result.data.data;
        })
        .catch((err) => {});
    }
  },

  methods: {
    formatDate(date) {
      if(date) {
        date = date.toISOString();
        date = date.split("T");
        date = date[0];
      }
      console.log("Date format", date);
      return date;
    },
    dateHastaSelect(event) {
      var firstDate = this.dateDesde.getUTCDate();
      var lastDate = event.getUTCDate();
      var diff = lastDate - firstDate;
      // By now no restrictions implemented
    },
    dateDesdeSelect(event) {
      this.datePicker2Disabled = false;
    },
    searchArea(event) {
      var name = event.query;
      this.selectedDivision = [];
      this.selectedCategoria_abm = [];

      AreaService.getAutocomplete(name)
        .then((result) => {
          this.area = result.data;
        })
        .catch((err) => {});
    },

    selectArea(event) {
      this.selectedDivision = [];

      this.optDisabled.division = false;

      this.selectedCategoria_abm = [];

      this.optDisabled.categoria = true;
    },

    searchDivision(event) {
      var name = event.query;
      var area_id = this.selectedArea.id;

      if (area_id != null) {
        DivisionService.getAutocomplete(name, area_id)
          .then((result) => {
            this.division = result.data;
          })
          .catch((err) => {});
      }
    },

    selectDivision(event) {
      this.selectedCategoria_abm = [];

      this.optDisabled.categoria = false;
    },

    searchCategoria(event) {
      var name = event.query;
      var division_id = this.selectedDivision.id;

      if (division_id != null) {
        CategoriaServiceABM.getAutocomplete(name, division_id)
          .then((result) => {
            this.categoria_abm = result.data;
          })
          .catch((err) => {});
      }
    },

    searchBrand(event) {
      if (this.paisSelect && this.selectedRetail && this.selectedCategoria) {
        var country = [this.paisSelect.country];
        var retail = [];
        var categoria = [];

        this.selectedRetail.map((item) => {
          retail.push(item.retail);
        });
        this.selectedCategoria.map((item) => {
          categoria.push(item.category);
        });

        this.datos = [];

        OldBrandService.getAutoComplete(retail, country, categoria, event.query)
          .then((result) => {
            result.data.map((item) => {
              var newObj = { name: item.brand, code: item.brand };

              this.datos.push(newObj);
            });
            this.brand = this.datos;
          })
          .catch((err) => {});
      }
    },

    hidePais() {
      this.$emit("ShowPais", this.paisSelect); //Function call back e passa os valores selecionados
      if (this.paisSelect != null) {
        this.clear();
        this.optDisabled.retail = false;
      } else {
        this.optDisabled.retail = true;
      }
    },

    showRetail() {
      this.$emit("ShowRetail", this.selectedRetail); //Function call back e passa os valores selecionados
      this.selectedRetailShow = this.selectedRetail;
    },

    hideRetail() {
      this.$emit("HideRetail", this.selectedRetail); //Function call back e passa os valores selecionados

      if (this.selectedRetail != null) {
        if (this.selectedRetailShow != this.selectedRetail) {
          this.selectedCategoria = null;
          this.selectedBrand = null;

          if (this.selectedRetail.length) {
            this.categoria = [];
            this.brand = [];
          }
        }

        if (this.type == "comparativo") {
        } else {
          this.optDisabled.categoria = false;
        }
      } else {
        if (this.type == "comparativo") {
        } else {
          this.optDisabled.categoria = true;
        }
      }
    },

    hideCategoria() {
      this.disabled = false;
      this.$emit("HideCategoria", this.selectedCategoria); //Function call back e passa os valores selecionados

      if (this.selectedCategoria != null) {
        if (this.selectedCategoria.length) {
          this.selectedBrand = null;
          this.brand = [];
        } else if (this.selectedCategoria.length == 0) {
          this.disabled = true;
        }
      }
    },

    inputArea() {
      this.selectedDivision = null;
      this.selectedCategoria_abm = null;
      this.optDisabled.division = false;
      this.optDisabled.categoria = true;
      this.$emit("areaInput", this.selectedArea);
    },

    inputDivision() {
      this.selectedCategoria_abm = null;
      this.optDisabled.categoria = false;
      this.$emit("divisionInput", this.selectedDivision);
    },

    getQuery() {
      var query = [];

      //Country
      if (this.paisSelect != null) {
        query.country = this.paisSelect.country;
      }

      //Retail
      if (this.selectedRetail != null) {
        query.retail = this.selectedRetail;
      }

      //Category
      if (this.selectedCategoria != null) {
        query.category = this.selectedCategoria;
      }

      //Brand ou category_abm
      if (this.selectedBrand != null) {
        query.brand = this.selectedBrand;
      }

      if (this.selectedCategoria_abm != null) {
        query.category_abm = this.selectedCategoria_abm;
      }

      query.date = this.dateHoy;

      if (this.selectedBrand_abm != null){
        query.brand = this.selectedBrand_abm;
      }

      query.start_date = this.formatDate(this.dateDesde);
      query.end_date = this.formatDate(this.dateHasta);

      this.$emit("getObtener", query);
    },

    clear() {
      this.selectedRetail = null;

      if (this.type != "comparativo") {
        this.selectedDivision = null;
        this.selectedArea = null;
        this.selectedBrand = null;
        this.selectedCategoria = null;
        this.retail = [];
        this.area = [];
        this.division = [];
        this.categoria = [];

        this.optDisabled = {
          pais: false,
          retail: true,
          area: false,
          division: true,
          categoria: true,
        };
      }
    },
    clearDates() {
      this.dateDesde = null;
      this.dateHasta = null;
    },
    clearCampos() {
      this.$emit("ClickClear"); //Function call back
      this.selectedCategoria = null;
      this.selectedRetail = null;
      this.selectedBrand = null;
      this.paisSelect = null;
      this.selectedArea = null;
      this.selectedDivision = null;
      this.dateDesde = null;
      this.dateHasta = null;

      if (this.chipsRetail) {
        this.retail = [];
      }

      if (this.chipsArea) {
        this.area = [];
      }

      if (this.chipsDivision) {
        this.division = [];
      }

      if (this.chipsCategoria) {
        this.categoria = [];
      }

      if (this.chipsBrand) {
        this.brand = [];
      }

      this.optDisabled = {
        pais: false,
        retail: true,
        area: false,
        division: true,
        categoria: true,
      };

      this.disabled = true;
    },

    disabledDaysController() {
      return [1];
    },

    searchBrandABM(event){
      var name = event.query;

      BrandService.getAutocomplete(name).then((result) => {
        this.brand_abm = result.data;
      }).catch((err) => {
        
      });
    }
  },
};
</script>
<style lang="">
</style>