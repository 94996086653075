import axios from 'axios';
import env from './../../env';

export const excelService = {

    async getExcel(name){
         return axios({
            url: env.API + "/" + name + "/import/template",
            method: 'GET',
            responseType: 'blob', // important
          }).then((response) => {
             const url = window.URL.createObjectURL(new Blob([response.data]));
             const link = document.createElement('a');
             link.href = url;
             link.setAttribute('download', 'Template - '+ name + '.xlsx'); //or any other extension
             document.body.appendChild(link);
             link.click();
          });
    },

    async postExcel(name, files){
      var formData = new FormData();
      console.log(files)
      formData.append('file', files);

      return axios({
         url: env.API + "/" + name + "/import",
         method: 'POST',
         responseType: 'blob',
         headers:{'content-type': 'multipart/form-data'},
         data: formData,
      });
   }
}