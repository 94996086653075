<template>
  <div>
    <div>
      <!-- @_____ == function callback -->
      <Query
        :paisList="paisList"
        :chipsArea="ChipsArea"
        :chipsCategoria="ChipsCategoria"
        :chipsDivision="ChipsDivision"
        :chipsRetail="ChipsRetail"
        @InputPais="getPais"
        @ClickClear="clearList"
        @ShowRetail="clickRetail"
        @areaInput="areaInput"
        @divisionInput="divisionInput"
        :chipsBrand="ChipsBrand"
        @getObtener="getObtener"
        :width="'auto'"
      ></Query>
    </div>

    <div :class="collapse">
      <Table
        :values="productService"
        :collapse="collapse"
        :editar="true"
        @descartar="descartarFunc"
      ></Table>
    </div>

    <div :class="'container-custom ' + spinner">
      <ProgressSpinner />
    </div>

    <div :class="collapse" v-if="productService.length > 0">
      <Paginator
        :options="options"
        :links="links"
        :oldProducts="false"
        :params="true"
        :props="props"
        @nextPage="handlerPaginator"
        @lastPage="handlerPaginator"
        @prevPage="handlerPaginator"
        @firstPage="handlerPaginator"
        @numberPage="handlerPaginator"
        @loading="loadings"
        :total="total"
      ></Paginator>
    </div>
  </div>
</template>
<script>
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import Chip from "primevue/chip";
import Toolbar from "primevue/toolbar";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import SplitButton from "primevue/splitbutton";
import Table from "./../../components/Tables/Table.vue";
import Query from "../../components/Query/QueryTaxonomizado.vue";

import ProgressSpinner from "primevue/progressspinner";

import { AreaService } from "../../services/AreaService";
import { DivisionService } from "../../services/DivisionService";
import { CategoriaServiceABM } from "../../services/CategoriaService";
import { BrandService } from "../../services/BrandService";
import { RetailsService } from "../../services/Taxonomia/RetailsService";
import { CountryService } from "../../services/Taxonomia/CountryService";
import { ProductsService } from "../../services/Taxonomia/ProductsService";

import Paginator from "./../../components/Paginator.vue";

export default {
  components: {
    DataTable,
    Dropdown,
    InputText,
    Column,
    MultiSelect,
    Chip,
    Toolbar,
    Button,
    Checkbox,
    SplitButton,
    Table,
    Query,
    ProgressSpinner,
    Paginator,
  },

  data() {
    return {
      // DATA - API
      productService: [],
      loading: false,

      //CHIPS
      //Pais
      paisList: [],
      paisSelect: null,

      //Retail
      ChipsRetail: [],

      //Categoria
      ChipsCategoria: [],

      //Brand
      ChipsBrand: [],

      //Area
      ChipsArea: [],

      //Division
      ChipsDivision: [],

      props: null,
      links: null,
      options: null,

      widthTable: "auto",
      collapse: "table-collapsed",
      spinner: "spinner-not",

      total: 0,
    };
  },

  created() {
    AreaService.getAll()
      .then((result) => {
        this.ChipsArea = result.data.data;
      })
      .catch((err) => {});

    BrandService.getAll()
      .then((result) => {
        this.ChipsBrand = result.data.data;
      })
      .catch((err) => {});

    CountryService.getAll()
      .then((result) => {
        var id = 0;
        result.data = result.data.map((item) => {
          id += 1;
          item.id = id;
          item.name_backup = item.name;

          item.name = item.name + " | " + item.country;
          this.paisList.push(item);
        });
      })
      .catch((err) => {});
  },

  methods: {
    clickRetail() {
      //Alert
      if (this.paisSelect == null) {
        this.$store.dispatch("alert_error", {
          message: "Seleccione el país primero",
          time: 2000,
        });
      } else {
        RetailsService.getPerCountry(this.paisSelect.country)
          .then((result) => {
            var names = [];
            var retailABC = [];

            this.ChipsRetail = result.data.map((item) => {
              let retailUC = item.retail.toUpperCase();
              item.name = retailUC + " | " + this.paisSelect.country;
              names.push(item.name);
              item.id = this.paisSelect.country;
              return item;
            });

            names.sort();

            names.map((name) => {
              this.ChipsRetail.map((retail) => {
                if (name == retail.name) {
                  retailABC.push(retail);
                }
              });
            });

            this.ChipsRetail = retailABC;
          })
          .catch((err) => {});
      }
    },

    areaInput(data, selectDivision) {
      this.ChipsDivision = []; //Clear chips

      DivisionService.getAll() //Request e filtro do id da area selecionada
        .then((result) => {
          this.ChipsDivision = result.data.data;
          this.ChipsDivision = this.ChipsDivision.filter(
            (divisions) => divisions.area_id == data.id
          );
          //Alerts
          if (this.ChipsDivision.length == 0) {
            this.$store.dispatch("alert_error", {
              message: "No encontramos divisiones con esta área.",
              time: 2000,
            });
          } else {
            this.$store.dispatch("alert_success_bottom", {
              message:
                "Encontramos un total de " +
                this.ChipsDivision.length +
                " divisions.",
              time: 2000,
            });
          }
        })
        .catch((err) => {});
    },

    divisionInput(data) {
      this.ChipsCategoria = []; //Clear chips

      CategoriaServiceABM.getAll() //Request e filtro da division selecionada pelo id
        .then((result) => {
          this.ChipsCategoria = result.data.data;
          this.ChipsCategoria = this.ChipsCategoria.filter(
            (categoria) => categoria.division_id == data.id
          );
          //Alerts
          if (this.ChipsCategoria.length == 0) {
            this.$store.dispatch("alert_error", {
              message: "No encontramos categorias con esta divisiones.",
              time: 2000,
            });
          } else {
            this.$store.dispatch("alert_success_bottom", {
              message:
                "Encontramos un total de " +
                this.ChipsCategoria.length +
                " categorias.",
              time: 2000,
            });
          }
        })
        .catch((err) => {});
    },

    taxonomizado(id) {
      console.log(id);
    },

    getPais(data) {
      this.paisSelect = data;
    },

    getQuery() {
      var query = [];
      query.push(this.selectedCategoria);
      query.push(this.selectedRetail);
      query.push(this.selectedBrand);
      query.push(this.paisSelect);
      query.push(this.selectedDivision);
      query.push(this.selectedArea);

      console.log(query);
    },

    handlerPaginator(page, props) {
      var id = 0;
      this.productService = [];

      console.log(page, props);

      ProductsService.getAllParamsPage(
        props.retails,
        props.categoria,
        [props.country],
        props.brands,
        page
      )
        .then((result) => {
          var id = 0;
          result.data.data.map((item) => {
            id++;

            item.country = item.taxonomy_product.country;
            item.last_seen = item.taxonomy_product.last_seen;
            item.link = item.taxonomy_product.link;
            item.md5_link = item.taxonomy_product.md5_link;
            item.model = item.taxonomy_product.model;
            item.product_name = item.name;

            item.retail = item.taxonomy_product.retail;

            item.edit = false;

            item.brand_abm = item.brand;
            item.nombre = item.name;
            item.categoria_abm = item.category;

            item.id_ = id;

            item.nombre_status = true;

            item.class = "";

            item.options = {
              brand_abm: { suggestions: [], disabled: true },
              name: { suggestions: [], disabled: true },
              categoria_abm: { suggestions: [], disabled: true },
              btn_agregar: { disabled: true },
              features: {
                disabled: true,
                feature_1: [],
                feature_2: [],
                feature_3: [],
                feature_4: [],
                feature_5: [],
              },
            };

            item.buttons = [
              {
                label: "Descartar",
                id: id,
                md5: item.md5_link,
                command: (click) => {
                  this.softDelete(click.item.md5);
                },
              },
              {
                label: "Cancelar",
                id: id,
                command: (click) => {
                  this.cancelar(click.item.id);
                },
              },
            ];

            this.productService.push(item); //Passando para props
          });
        })
        .catch((err) => {});

      this.loadings(false);
    },

    getObtener(data) {
      var country = "";
      var retails = [];
      var categoria = [];
      var brands = [];

      country = [data.country];

      if (data.retail) {
        retails = data.retail.map((item) => {
          return item.retail;
        });
      }

      if (data.category) {
        categoria = data.category.map((item) => {
          return item.id;
        });
      }

      if (data.brand) {
        brands = data.brand.map((item) => {
          return item.id;
        });
      }

      if (retails.length == 0) {
        this.$store.dispatch("alert_error", {
          message: "Retail no ha sido llenado",
          time: 3000,
        });
      } else if (categoria.length == 0) {
        this.$store.dispatch("alert_error", {
          message: "Category no ha sido llenado",
          time: 3000,
        });
      } else {
        this.productService = [];
        this.widthTable = "auto";
        this.collapse = "table-collapsed";
        this.spinner = "spinner-loading";

        ProductsService.getAllParams(retails, categoria, country, brands)
          .then((result) => {
            var id = 0;
            result.data.path = result.data.path + '?page=';

            this.total = result.data.total;

            result.data.data.map((item) => {
              id++;

              item.country = item.taxonomy_product.country;
              item.last_seen = item.taxonomy_product.last_seen;
              item.link = item.taxonomy_product.link;
              item.md5_link = item.taxonomy_product.md5_link;
              item.model = item.taxonomy_product.model;
              item.product_name = item.name;

              item.retail = item.taxonomy_product.retail;

              item.edit = false;

              item.brand_abm = item.brand;
              item.nombre = item.name;
              item.categoria_abm = item.category;

              item.id_ = id;

              item.nombre_status = true;

              item.class = "";


              item.options = {
                brand_abm: { suggestions: [], disabled: true },
                name: { suggestions: [], disabled: true },
                categoria_abm: { suggestions: [], disabled: true },
                btn_agregar: { disabled: true },
                features: {
                  disabled: true,
                  feature_1: [],
                  feature_2: [],
                  feature_3: [],
                  feature_4: [],
                  feature_5: [],
                },
              };

              this.productService.push(item); //Passando para props
            });
            console.log(result.data)
            this.options = result.data;
            this.links = result.data.links;
            this.props = { retails, categoria, country, brands };

            this.loadings(false);
          })
          .catch((err) => {});
      }
    },

    clearList() {
      this.ChipsRetail = [];
      this.ChipsCategoria = [];
      this.ChipsDivision = [];

      this.paisSelect = null;
      this.selectedRetail = null;
      this.selectedCategoria = null;

      this.productService = [];

      this.widthTable = "auto";
      this.collapse = "table-collapsed";
      this.spinner = "spinner-not";
    },

    loadings(boolean) {
      if (boolean == true) {
        this.collapse = "table-collapsed"; //Css propriedades
        this.spinner = "spinner-loading"; //Css propriedades
      } else {
        setTimeout(() => {
          this.collapse = "not-collapsed"; //Css propriedades
          this.spinner = "spinner-not"; //Css propriedades
        }, 1500);
      }
    },

    descartarFunc(){
      console.log('descartado');
      this.total -= 1;
    },
  },
};
</script>
<style lang=""></style>
